import React from 'react'
import styled from 'styled-components'

import { useArea, useParam, useIsPortrait } from '../hooks'

const Container = styled.div`
    position: relative;
    height: ${p => p.isPortrait ? '40vh' : '100vh'};
	box-sizing: border-box;
`

export default function Area(props) {
	const isPortrait = useIsPortrait()
	const id = useParam('id')
	const components = useArea(id)

	return (
		<Container isPortrait={isPortrait}>
			{components}
		</Container>
	)
}