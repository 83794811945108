import React, { useState, useEffect } from 'react'

const PersonalChat = React.createContext()

const LIST = 0
const CHAT = 1

export function PersonalChatContext(props) {
    const [state, setState] = useState({
        active: false,
        menu: LIST,
        selected: {},
        toggle() { setState({...this, active: !this.active, menu: LIST, selected: null}) },
        openChat(user) { setState({...this, active: true, menu: CHAT, selected: user}) },
        closeChat() { setState({...this, selected: null, menu: LIST}) },
    }) 

    return <PersonalChat.Provider value={state}>{ props.children }</PersonalChat.Provider>
}

export default PersonalChat