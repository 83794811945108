import React, { useState } from 'react'
import styled from 'styled-components'
import { useStore } from '../../hooks'
import { key } from 'firebase-key'
import { uploadImage } from '../../firebase/storage'
import { useHistory } from 'react-router-dom'

import { Text, Button, Image, FadeInUp, UploadFile, InputField, IconButton } from '../UI'
import PresentationImages from './PresentationImages'
import closeIcon from '../../graphics/icons/arrow-left.svg'
import Dropdown from '../Dropdown'
import { URLs } from '../../context/constants'
import { useAuth, useNotifications } from '../../hooks'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import { downloadCompanyPurchaseHistory } from '../../utility/analyticsCSV'

import Catalog from './Stands/Catalog'

const LIST = 0
const SELECTED = 1
const CATALOG = 2

const Container = styled.div`
    height: 100%;
`

export default function Stands() {
	const [state, setState] = useState({
		menu: LIST,
		selected: null,
	})
	const [store, dispatch] = useStore()

	// Get UI content.
	const getContent = () => {
		if (state.menu === LIST) return <StandList
			selectStand={s => setState({ ...state, menu: SELECTED, selected: s })}
		/>
		if (state.menu === SELECTED) return <EditStand
			catalogHandler={() => {
				setState(prev => { return { ...prev, menu: CATALOG } })
			}}
			backHandler={() => {
				setState({ ...state, menu: LIST, selected: null })
			}}
			stand={state.selected}
		/>
		if (state.menu === CATALOG) return <Catalog
			stand={state.selected}
			backHandler={() => {
				setState(prev => { return { ...prev, menu: SELECTED } })
			}}
			saveStand={s => {
				dispatch({ type: 'set-stand', payload: s })
			}}
		/>
	}

	return (
		<Container>
			{getContent()}
		</Container>
	)
}

const EditStandContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
    padding-bottom: 2rem;
`

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`

const BackgroundHolder = styled.div`
    width: 20rem;
    height: 12rem;
    border: .1rem solid ${p => p.theme.light};
`

function EditStand(props) {
	const history = useHistory()
	const [store, dispatch] = useStore()
	const [state, setState] = useState({})
	const [stand, setStand] = useState(props.stand)
	const auth = useAuth()
	const notifications = useNotifications()

	const isExpositor = auth.isExpositor()

	// Upload stand icon.
	const uploadIcon = async () => {
		const icon = state.iconImage
		setState({ ...state, iconImage: null, loading: true })
		try {
			const id = await uploadImage(state.iconImage, stand.icon)
			saveStand({ ...stand, icon: id })
		} catch (error) {
			notifications.notify(error)
		}
		setState({ ...state, loading: false })
	}

	const saveStand = s => {
		setStand(s)
		dispatch({ type: 'set-stand', payload: s })
	}

	const editStand = () => {
		history.push(`${URLs.areaEditor}?type=stands&id=${stand.id}`)
	}

	const deleteStand = () => {
		if (stand.pavillion && stand.id in store.pavillions[stand.pavillion].stands) {
			let pavillion = store.pavillions[stand.pavillion]
			delete pavillion.stands[stand.id]
			dispatch({ type: 'set-pavillion', payload: pavillion })
		}

		dispatch({ type: 'delete-stand', payload: stand })
		props.backHandler()
	}

	// Get options (companies, pavillions)
	const companiesOptions = store.companies ? Object.values(store.companies) : []
	const pavillionOptions = store.pavillions ? Object.values(store.pavillions) : []
	const selectedCompany = stand.company ? store.companies[stand.company] : null
	const selectedPavillion = stand.pavillion ? store.pavillions[stand.pavillion] : null

	const selectCompanyHandler = id => {
		saveStand({ ...stand, company: id })
	}

	const selectPavillionHandler = id => {
		// Remove stand from pavillion.
		if (stand.pavillion && store.pavillions[stand.pavillion] && stand.id in store.pavillions[stand.pavillion].stands) {
			let pavillion = store.pavillions[stand.pavillion]
			delete pavillion.stands[stand.id]
			dispatch({ type: 'set-pavillion', payload: pavillion })
		}

		// Add stand to pavillion.
		{
			let pavillion = store.pavillions[id]
			if (!pavillion.stands) pavillion.stands = {}
			pavillion.stands[stand.id] = stand.id
			dispatch({ type: 'set-pavillion', payload: pavillion })
		}

		// Save stand.
		saveStand({ ...stand, pavillion: id })
	}

	async function downloadPurchaseHistory() {
		if (state.downloadingHistory) return
		const company = store?.companies[stand.company]
		setState(p => ({ ...p, downloadingHistory: true }))
		if (company) await downloadCompanyPurchaseHistory(store, company, stand.id)
		setState(p => ({ ...p, downloadingHistory: false }))
	}

	return (
		<EditStandContainer>
			<CloseButton><IconButton onClick={props.backHandler} image={closeIcon} /></CloseButton>
			<Text bold H1>{stand.name}</Text>
			<Button onClick={() => downloadPurchaseHistory()} style={{ height: '2rem', width: '15rem' }}>{state.downloadingHistory ? 'Descargando...' : 'Historial de compra'}</Button>

			{!isExpositor && <Text bold H2>Empresa</Text>}
			{!isExpositor && <Dropdown
				style={{ width: '20rem' }}
				placeholder='Seleccionar una empresa'
				options={companiesOptions}
				onSelect={selectCompanyHandler}
				selected={selectedCompany}
			/>}

			<Text bold H2>Nombre del Stand</Text>
			<InputField
				style={{ width: '20rem' }}
				value={stand.name}
				onChange={v => saveStand({ ...stand, name: v })}
			/>

			<Text bold H2>Descripción del Stand</Text>
			<InputField
				style={{ width: '20rem' }}
				value={stand.description}
				onChange={v => saveStand({ ...stand, description: v })}
			/>

			<Text bold H2>Código de ingreso</Text>
			<InputField
				style={{ width: '20rem' }}
				value={stand.password}
				onChange={v => saveStand({ ...stand, password: v })}
			/>

			<Text bold H2>Orden</Text>
			<InputField
				style={{ width: '20rem' }}
				placeholder='Ejemplos: 0 | 4 | .3'
				value={stand.order}
				onChange={v => saveStand({ ...stand, order: v })}
			/>

			<Text bold H2>Tags</Text>
			<InputField
				style={{ width: '20rem' }}
				value={stand.tags}
				onChange={v => saveStand({ ...stand, tags: v })}
			/>

			<Text bold H2>Pabellón</Text>
			<Dropdown
				style={{ width: '20rem' }}
				placeholder='Seleccionar un pabellón'
				options={pavillionOptions}
				onSelect={selectPavillionHandler}
				selected={selectedPavillion}
			/>

			<PresentationImages saveArea={saveStand} area={stand} />

			{stand.icon && <BackgroundHolder><Image contain url={stand.icon} /></BackgroundHolder>}
			<Text bold H2>Icono de Stand</Text>
			{state.loading ? <Text H2 bold>Subiendo...</Text> : <UploadFile style={{ height: '2rem', width: '15rem' }} fileHanlder={file => setState({ ...state, iconImage: file })}>Subir nueva imagen</UploadFile>}
			{state.iconImage && <Button onClick={uploadIcon} style={{ height: '2rem', width: '15rem' }}>Subir</Button>}

			<Text bold H2>Editar Catálogo</Text>
			<Button onClick={() => props.catalogHandler()} style={{ height: '2rem', width: '15rem' }}>Editar Catálogo</Button>

			<Text bold H2>Editar stand (botones, imágenes, videos, etc)</Text>
			<Button onClick={editStand} style={{ height: '2rem', width: '15rem' }}>Editar stand</Button>

			{!isExpositor && <Text bold H2>Otros</Text>}
			{!isExpositor && <Button onClick={deleteStand} style={{ height: '2rem', width: '15rem' }}>Eliminar stand</Button>}
		</EditStandContainer>
	)
}

const ListContainer = styled.div`
    padding: 1rem;
    height: 100%;
    display: grid;
    grid-template-rows: 3rem auto;
    grid-gap: 1rem;
`

const StandsGrid = styled.div`
    height: 100%;
`

export const AutoRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 3rem;
    width: min-content;
    height: 2rem;
`

function StandList(props) {
	const [store, dispatch] = useStore()
	const auth = useAuth()
	const isExpositor = auth.isExpositor()
	const [search, setSearch] = useState('')
	const [state, set] = useState({
		loadingPurchaseHistory: false,
	})

	// New stand.
	const createStandHandler = () => {
		const newStand = {
			id: key(),
			name: 'Nuevo Stand',
		}

		dispatch({ type: 'set-stand', payload: newStand })
		props.selectStand(newStand)
	}

	// Filter stands with search and user permissions (Expositors)
	const filter = s => {
		if (isExpositor) {
			if (auth?.user?.typeData?.company != s.company) return false
		}

		if (search === '') return true
		const v = search.toLowerCase()

		if (s.name && s.name.toLowerCase().includes(v)) return true
		if (s.description && s.description.toLowerCase().includes(v)) return true
		if (s.tags && s.tags.toLowerCase().includes(v)) return true

		return false
	}

	// Stands list. Sorted.
	const list = store.stands && Object.values(store.stands)
		.filter(filter)
		.sort((a, b) => {
			const av = a.order ? parseFloat(a.order) : Number.MAX_SAFE_INTEGER
			const bv = b.order ? parseFloat(b.order) : Number.MAX_SAFE_INTEGER
			return av > bv ? 1 : -1
		})
		.map(s => <StandPreview
			key={s.id}
			stand={s}
			pavillionName={s.pavillion && store.pavillions && s.pavillion in store.pavillions && store.pavillions[s.pavillion].name}
			onClick={() => props.selectStand(s)}
		/>)

	const rowRenderer = ({ key, index, style }) => {
		return (
			<div key={key} style={style}>
				{list[index]}
			</div>
		)
	}

	// Check limitations.
	function hasExceeded(type, object) {
		if (!store?.configurations?.limitations) return false
		const max = store?.configurations?.limitations[type]
		if (!max || max === '') return false

		if (!object) return false

		const amount = Object.values(object).length

		return amount >= max
	}

	return (
		<ListContainer>
			<AutoRow>
				<Text bold H1 oneline>Stands: {list?.length || 0}</Text>
				{!isExpositor && (hasExceeded('stands', store.stands) ? <Text middle oneline bold H2>Límite Excedido</Text> : <Button onClick={createStandHandler}>Agregar nuevo stand</Button>)}
				<InputField
					placeholder='Buscar Stand'
					value={search}
					onChange={setSearch}
				/>
				{/* {!state.loadingPurchaseHistory && <Button onClick={() => {
					set(p => ({ ...p, loadingPurchaseHistory: true }))
					downloadPurchaseHistoryCsv(store).then(() => set(p => ({ ...p, loadingPurchaseHistory: false })))
				}}>Historial de Compra</Button>} */}
			</AutoRow>
			<StandsGrid>
				<AutoSizer>
					{
						({ height, width }) => (
							<FadeInUp>
								<List
									height={height}
									rowCount={list ? list.length : 0}
									rowHeight={100}
									rowRenderer={rowRenderer}
									width={width}
								/> </FadeInUp>
						)
					}
				</AutoSizer>
			</StandsGrid>
		</ListContainer>
	)
}

const StandPreviewContainer = styled.div`
    background-color: ${p => p.theme.medium};
    display: grid;
    grid-template-columns: 1fr .5fr 1fr 1.5fr;
    cursor: pointer;
    transition: ${p => p.theme.transitionDuration};
    :hover {
        border: .1rem solid ${p => p.theme.primary};
    }
`

const StandPreviewInfo = styled.div`
    background-color: ${p => p.theme.light};
    padding: 2rem;
`

function StandPreview(props) {
	return (
		<StandPreviewContainer onClick={props.onClick}>
			<Image url={props.stand.background} />
			<Image contain url={props.stand.icon} />
			<Text bold H3 middle center>{props.pavillionName}</Text>
			<StandPreviewInfo>
				<Text bold dark H3>{props.stand.name}</Text>
			</StandPreviewInfo>
		</StandPreviewContainer>
	)
}