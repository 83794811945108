import React, { useReducer, useState } from 'react'
import { getContent, subscribeToContent, setContent } from '../firebase/database'
import { databasePaths } from '../firebase'
import { getUnixTime } from '../utility'
import { key } from 'firebase-key'
import { useLanguage } from '../hooks'

const Store = React.createContext()

let lastUserSnapshot = undefined

// Handle dictionary changes.
function dictionaryReducer(databasePath, dictionary, action) {
    if (!dictionary) dictionary = {}
    if (!action.payload.id) return dictionary

    if (action.type.includes('set') && action.payload.id) {
        dictionary[action.payload.id] = action.payload
        setContent(`${databasePath}/${action.payload.id}`, action.payload)
    }

    if (action.type.includes('delete') && action.payload.id) {
        delete dictionary[action.payload.id]
        setContent(`${databasePath}/${action.payload.id}`, {})
    }

    return dictionary
}

function objectReducer(databasePath, object, action) {
    if (!object) object = {}
    if (!databasePath) return object

    if (action.type.includes('set')) {
        object = action.payload
        setContent(`${databasePath}`, action.payload)
    }

    return object
}

async function sendMessage(payload) {
    if (!payload.chatGroup || !payload.message || !payload.sender) return

    let timeStamp = Date.now()

    try {
        timeStamp = (await getUnixTime()) * 1000
    } catch (error) {
        timeStamp = Date.now()
    }

    const message = {
        id: key(),
        text: payload.message,
        timestamp: timeStamp || Date.now(),
        user: payload.sender
    }

    setContent(`${databasePaths.chatGroups}/${payload.chatGroup}/messages/${message.id}`, message)
}

async function deleteMessage(payload) {
    if (!payload.chatGroup || !payload.messageId) return
    setContent(`${databasePaths.chatGroups}/${payload.chatGroup}/messages/${payload.messageId}`, {})
}

function reducer(state, action) {
    if (action.type === 'init') {
        return action.payload
    }

    if (action.type === 'send-message') {
        sendMessage(action.payload)
        return { ...state }
    }

    if (action.type === 'delete-message') {
        deleteMessage(action.payload)
        return { ...state }
    }

    if (action.type.includes('company')) return { ...state, companies: dictionaryReducer(databasePaths.companies, state.companies, action) }
    if (action.type.includes('stand')) return { ...state, stands: dictionaryReducer(databasePaths.stands, state.stands, action) }
    if (action.type.includes('pavillion')) return { ...state, pavillions: dictionaryReducer(databasePaths.pavillions, state.pavillions, action) }
    if (action.type.includes('user')) return { ...state, users: dictionaryReducer(databasePaths.users, state.users, action) }
    if (action.type.includes('videoRoom')) return { ...state, videoRooms: dictionaryReducer(databasePaths.videoRooms, state.videoRooms, action) }
    if (action.type.includes('user')) return { ...state, users: dictionaryReducer(databasePaths.users, state.users, action) }
    if (action.type.includes('chatGroup')) return { ...state, chatGroups: dictionaryReducer(databasePaths.chatGroups, state.chatGroups, action) }
    if (action.type.includes('area')) return { ...state, areas: dictionaryReducer(databasePaths.areas, state.areas, action) }
    if (action.type.includes('ranking')) return { ...state, ranking: dictionaryReducer(databasePaths.ranking, state.ranking, action) }
    if (action.type.includes('productCategory')) return { ...state, productCategories: dictionaryReducer(databasePaths.productCategories, state.productCategories, action) }

    if (action.type.includes('notification')) return { ...state, notifications: dictionaryReducer(databasePaths.notifications, state.notifications, action) }

    if (action.type.includes('configurations')) return { ...state, configurations: objectReducer(databasePaths.configurations, state.configurations, action) }

    if (action.type.includes('home')) return { ...state, home: objectReducer(databasePaths.home, state.home, action) }
    if (action.type.includes('lobby')) return { ...state, lobby: objectReducer(databasePaths.lobby, state.lobby, action) }
    if (action.type.includes('networking')) return { ...state, networking: objectReducer(databasePaths.networking, state.networking, action) }

    return state
}

async function initFromFirebase(dispatch, selectLanguage) {
    try {
        let pavillions = await getContent(databasePaths.pavillions)
        let videoRooms = await getContent(databasePaths.videoRooms)
        let home = await getContent(databasePaths.home)
        let lobby = await getContent(databasePaths.lobby)
        let stands = await getContent(databasePaths.stands)
        let companies = await getContent(databasePaths.companies)
        let networking = await getContent(databasePaths.networking)
        let users = await getContent(databasePaths.users)
        let chatGroups = await getContent(databasePaths.chatGroups)
        let configurations = await getContent(databasePaths.configurations)
        let areas = await getContent(databasePaths.areas)
        let ranking = await getContent(databasePaths.ranking)
        let productCategories = await getContent(databasePaths.productCategories)
        let notifications = await getContent(databasePaths.notifications)

        {
            // TEMP local product categories.
            const initProductCategories = {
                'shoes': {
                    id: 'shoes',
                    name: 'Zapatos',
                    dropdowns: {
                        color: {
                            order: 1,
                            name: 'Color',
                            options: {
                                'blue': { id: 'blue', name: 'Azul', order: 1 },
                                'red': { id: 'red', name: 'Rojo', order: 2 },
                                'green': { id: 'green', name: 'Verde', order: 3 },
                            }
                        },
                        size: {
                            order: 2,
                            name: 'Talle',
                            options: {
                                's': { id: 's', name: 'S', order: 1 },
                                'm': { id: 'm', name: 'M', order: 2 },
                                'l': { id: 'l', name: 'L', order: 3 },
                            }
                        }
                    }
                }
            }

            if (!productCategories) productCategories = initProductCategories
        }

        // Set language.
        // selectLanguage(configurations?.language ?? 'spanish')

        // Set offline users.
        if (users) {
            Object.values(users).forEach(user => {
                if (user.online && (!user.lastPing || Date.now() - user.lastPing > 660000) && user.id)
                    setContent(`${databasePaths.users}/${user.id}/online`, false)

                // Revoke universall access pass.
                // if(user.id) setContent(`${databasePaths.users}/${user.id}/universalAccess`, false)
            })
        }

        function set() {
            dispatch({
                type: 'init',
                payload: {
                    pavillions: pavillions,
                    videoRooms: videoRooms,
                    home: home,
                    lobby: lobby,
                    stands: stands,
                    companies: companies,
                    networking: networking,
                    users: users,
                    chatGroups: chatGroups ? chatGroups : {},
                    configurations: configurations ? configurations : {},
                    areas: areas,
                    ranking: ranking || {},
                    productCategories,
                    notifications: notifications ?? {},
                }
            })
        }

        set()

        subscribeToContent(databasePaths.pavillions, v => {
            pavillions = v
            set()
        })
        subscribeToContent(databasePaths.notifications, v => {
            notifications = v
            set()
        })
        subscribeToContent(databasePaths.videoRooms, v => {
            videoRooms = v
            set()
        })
        subscribeToContent(databasePaths.home, v => {
            home = v
            set()
        })
        subscribeToContent(databasePaths.lobby, v => {
            lobby = v
            set()
        })
        subscribeToContent(databasePaths.stands, v => {
            stands = v
            set()
        })
        subscribeToContent(databasePaths.companies, v => {
            companies = v
            set()
        })

        subscribeToContent(databasePaths.users, v => { lastUserSnapshot = v })

        setInterval(() => {
            if (lastUserSnapshot) {
                users = lastUserSnapshot
                lastUserSnapshot = undefined
                set()
            }
        }, 1000)

        subscribeToContent(databasePaths.networking, v => {
            networking = v
            set()
        })
        subscribeToContent(databasePaths.chatGroups, v => {
            chatGroups = v
            set()
        })
        subscribeToContent(databasePaths.configurations, v => {
            configurations = v
            set()
        })
        subscribeToContent(databasePaths.areas, v => {
            areas = v
            set()
        })
        subscribeToContent(databasePaths.ranking, v => {
            ranking = v
            set()
        })
    }

    catch (error) { console.error(error) }
}

export function StoreContext(props) {
    const [state, dispatch] = useReducer(reducer, {
        configurations: {},
    })
    const [init, setInit] = useState(true)
    const language = useLanguage()

    if (init) {
        setInit(false)
        initFromFirebase(dispatch, language.selectLanguage)
    }

    return <Store.Provider value={[state, dispatch]}>{props.children}</Store.Provider>
}

export default Store