import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Rnd } from 'react-rnd'

const Background = styled.div`
    height: 100%;
    border: .3rem solid ${p => p.theme.primary};
    border-radius: .3rem;
    cursor: pointer;
    :hover { 
        border: .3rem solid ${p => p.theme.primaryHover};
    }
`

export default function ResizableContainer(props) {
    const [state, setState] = useState({
        x: props.position ? parseFloat(props.position.x) * window.innerWidth / 100 : window.innerWidth / 2,
        y: props.position ? parseFloat(props.position.y) * window.innerHeight / 100 : window.innerHeight / 2,
    })

    const getX = () => state.x / window.innerWidth * 100
    const getY = () => state.y / window.innerHeight * 100

    useEffect(() => {
        if (props.updatePositionHandler) {
            props.updatePositionHandler({
                width: `${state.width}`,
                height: `${state.height}`,
                x: `${getX()}%`,
                y: `${getY()}%`
            })
        }
    }, [state])

    useEffect(() => {
        setState(p => ({
            ...p,
            width: !props.resizable ? 50 : props.size ? props.size.x : '20%',
            height: !props.resizable ? 40 : props.size ? props.size.y : '20%'
        }))
    }, [props.resizable])

    return (
        <Rnd
            bounds='parent'
            size={{ width: state.width, height: state.height }}
            position={{ x: state.x, y: state.y }}
            onDragStop={(e, d) => setState({ ...state, x: d.x, y: d.y })}
            onResizeStop={(e, direction, ref, delta, position) => setState({ width: ref.style.width, height: ref.style.height, x: position.x, y: position.y })}
            enableResizing={props.resizable && props.editable ? { bottom: true, bottomLeft: true, bottomRight: true, left: true, right: true, top: true, topLeft: true, topRight: true } : {}}
            disableDragging={!props.editable}
        >
            <Background onClick={props.onClick}>
                {props.children}
            </Background>
        </Rnd>
    )
}
