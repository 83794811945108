import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './css/animation.css'
import App from './App'

import { getContent } from './firebase/database'

// Theme.
import { ThemeProvider } from 'styled-components'
import Theme from './theme/new'

// Context.
import { StoreContext } from './store'
import { AuthContext } from './context/AuthContext'
import { LanguageContext } from './context/LanguageContext'
import { SearchContext } from './context/SearchContext'
import { PopupContext } from './context/PopupContext'
import { UserMoreInfoContext } from './context/UserMoreInfoContext'
import { NotificationsContext } from './context/NotificationsContext'
import { PersonalChatContext } from './context/PersonalChatContext'
import { VideoCallContext } from './context/VideoCallContext'
import { ViewportContext } from './context/ViewportContext'
import { ShoppingListContext } from './context/ShoppingListContext'
import { ElementsManagerContext } from './context/ElementsManagerContext'
import { CatalogContext } from './context/CatalogContext'
import { CompanyPosterContext } from './context/CompanyPosterContext'

function Container() {
    const [theme, setTheme] = useState({dark: 'black'})
    useEffect(() => {
        // Online Theme.
        (async function() { 
            const configTheme = await getContent('configurations/theme')
            if (configTheme) Object.keys(configTheme).filter(k => configTheme[k]).forEach(k => Theme[k] = configTheme[k])
            setTheme(Theme)
         })()
    }, [])

    return (
    <ThemeProvider theme={ theme }>
        <LanguageContext>
            <ElementsManagerContext>
                <PopupContext>
                    <SearchContext>
                        <AuthContext>
                                <StoreContext>
                                    <NotificationsContext>
                                        <ViewportContext>
                                            <UserMoreInfoContext>
                                                <ShoppingListContext>
                                                    <PersonalChatContext>
                                                        <VideoCallContext>
															<CatalogContext>
																<CompanyPosterContext>
																	<App />
																</CompanyPosterContext>
															</CatalogContext>
                                                        </VideoCallContext>
                                                    </PersonalChatContext>
                                                </ShoppingListContext>
                                            </UserMoreInfoContext>
                                        </ViewportContext>
                                    </NotificationsContext>
                                </StoreContext>
                        </AuthContext>
                    </SearchContext>
                </PopupContext>
            </ElementsManagerContext>
        </LanguageContext>
    </ThemeProvider>
    )
}

const preventDefault = e => e.preventDefault();// When rendering our container
window.addEventListener('touchmove', preventDefault, {
   passive: false
});// Remember to clean up when removing it
window.removeEventListener('touchmove', preventDefault);


// Set css mouse position.
const root = document.documentElement
document.addEventListener('mousemove', evt => {
    let x = (evt.clientX / window.innerWidth * 100) + '%';
    let y = (evt.clientY / window.innerHeight * 100) + '%';

    root.style.setProperty('--mouse-x', x)
    root.style.setProperty('--mouse-y', y)
})

ReactDOM.render(<Container />, document.getElementById('root'))