import React, { useState } from 'react'
import { key as generateKey } from 'firebase-key'

const Notifications = React.createContext({})

export function NotificationsContext(props) {
    const [state, reducer] = useState({
        notify: (text, time = 3000) => {
            let key = generateKey()
            state.notifications[key] = {
                text: text,
                state: true
            }
            setTimeout(() => {
                if (!state.notifications[key]) return
                state.notifications[key].state = false
                state.save()
                setTimeout(() => {
                    delete state.notifications[key]
                    state.save()
                }, 1000)
            }, time)
            state.save()

            return key
        },
        closeNotification: key => {
            if (!state.notifications[key]) return
            state.notifications[key].state = false
            state.save()
            setTimeout(() => {
                delete state.notifications[key]
                state.save()
            }, 1000)
        },
        notifications: {},
        save: () => reducer({ ...state })
    })

    return (
        <Notifications.Provider value={state}>
            { props.children}
        </Notifications.Provider>
    )
}

export default Notifications