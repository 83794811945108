import React, { useState } from 'react'
import styled from 'styled-components'

import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'

import { Text, Button } from '../../UI'

const Container = styled.div`
    position: absolute;

    background-color: ${p => p.theme.light};
    padding: 2rem;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
`

const CalendarContainer = styled.div`
    height: 20rem;
`

const Background = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .8);
`

const Panel = styled.div`
    display: grid;
    grid-gap: 1rem;
`

interface IProps {
    from: Date,
    setFrom(date: Date): void,
    to: Date,
    setTo(date: Date): void,
    filter(value: boolean): void,
}

export default function TimePeriod({ from, setFrom, to, setTo, filter }: IProps) {
    return (
        <Background>
        <Container>
            <Panel>
                <Text bold H1 primary>Desde:</Text>
                <CalendarContainer>
                <Calendar 
                    value={from}
                    onChange={setFrom}
                />
                </CalendarContainer>
                <Button onClick={() => filter(false)} style={{ height: '2rem' }}>Cancelar</Button>
            </Panel>
            <Panel>
                <Text bold H1 primary>Hasta:</Text>
                <CalendarContainer>
                <Calendar 
                    value={to}
                    onChange={setTo}
                />
                </CalendarContainer>
                <Button onClick={() => filter(true)} style={{ height: '2rem' }}>Filtrar</Button>
            </Panel>
        </Container>
        </Background>
    )
}