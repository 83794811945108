export const URLs = {
    home: '/',
    area: '/area',
    pavillion: '/pavillion',
    videoRoom: '/video-room',
    contact: '/contact',
    support: '/support',
    termsAndConditions: '/terms-and-conditions',
    privacy: '/privacy',
    stand: '/stand',
    controlPanel: '/control-panel',
    areaEditor: '/area-editor',
}