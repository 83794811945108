import React, { useState } from 'react'
import styled from 'styled-components'

import { uploadImage } from '../../firebase/storage'
import { Text, Button, Background, Image, FadeInUp, UploadFile, InputField, IconButton } from '../UI'
import { useNotifications } from '../../hooks'

import deleteIcon from '../../graphics/icons/delete.svg'

const Container = styled.div`
    display: grid;
    grid-gap: 1rem;
`

const DeleteButton = styled.div`
    position: absolute;
    top: .3rem;
    right: 0;
    width: 3rem;
    height: 3rem;

    opacity: 0;
    transition: .3s;
`

const ImageHolderContainer = styled.div`
    position: relative;
    border: .1rem solid ${p => p.theme.light};

    :hover {
        ${DeleteButton} {
            opacity: 1;
            top: 0;
        }
    }
`

const ImageGridContainer = styled.div`
    height: 12rem;
    width: 100%;
    overflow: auto;
`
const ImageGrid = styled.div`
    display: grid;
    height: 100%;
    grid-gap: 1rem;
    width: min-content;
    grid-auto-flow: column;
    grid-auto-columns: 20rem;
`

function ImageHolder(props) {
    return (
        <ImageHolderContainer>
            <DeleteButton><IconButton onClick={() => props.deleteHandler(props.image)} image={deleteIcon}/></DeleteButton>
            <Image contain url={props.image} />
        </ImageHolderContainer>
    )
}

export default function PresentationImages(props) {
    const [state, setState] = useState({ })
    const notifications = useNotifications()

    // Upload image, add it to `backgrounds` if multiple or to `background` if single.
    const uploadHandler = async () => {
        const image = state.backgroundImage
        setState({...state, backgroundImage: null, loading: true})
        try {
            const area = {...props.area}
            if (area?.backgrounds?.images && Object.values(area.backgrounds.images).length >= 6) {
                notifications.notify('No se puede tener más de 6 imágenes')
                setState({...state, loading: false})
                return
            }

            const id = await uploadImage(image)

            if (area.background && !area.backgrounds) {

                area.backgrounds = { time: 2, images: {} }
                area.backgrounds.images[area.background] = area.background
                area.backgrounds.images[id] = id
                delete area.background
            }
            else if (!area.background && !area.backgrounds) area.background = id
            else if (area.backgrounds && area.backgrounds.images) area.backgrounds.images[id] = id

            props.saveArea({...area})

        } catch (error) {
            notifications.notify(error)
        }
        setState({...state, loading: false})
    }

    // Delete image, and set `background` if only one image remains.
    const deleteHandler = image => {
        const area = {...props.area}

        if (area.backgrounds && area.backgrounds.images) {
            delete area.backgrounds.images[image]

            if (Object.values(area.backgrounds.images).length === 1) {
                area.background = Object.values(area.backgrounds.images)[0]
                delete area.backgrounds
            }
        }
        else if (area.background && area.background === image) delete area.background

        props.saveArea({...area})
    }

    const getImages = () => {
        if (props.area.backgrounds && props.area.backgrounds.images) 
            return Object.values(props.area.backgrounds.images).map(image => 
                <ImageHolder deleteHandler={deleteHandler} key={image} image={image}/>)
        
        else if (props.area.background) return <ImageHolder deleteHandler={deleteHandler} image={props.area.background} />
    }

    return (
        <Container>
            <ImageGridContainer>
                <ImageGrid>
                    {getImages()}
                </ImageGrid>
            </ImageGridContainer>

            <Text bold H2>Agregar Fondo</Text>
            {state.loading ? <Text H2 bold>Subiendo...</Text> : <UploadFile style={{height: '2rem', width: '15rem'}} fileHanlder={file => setState({...state, backgroundImage: file})}>Subir nueva imagen</UploadFile>}
            {state.backgroundImage && <Button onClick={uploadHandler} style={{height: '2rem', width: '15rem'}}>Subir</Button>}

            {props.area.backgrounds && <Text H2 bold>Tiempo de transición</Text>}
            {props.area.backgrounds && <InputField
                style={{width: '20rem'}}
                value={props.area.backgrounds.time}
                onChange={v => props.saveArea({...props.area, backgrounds: {...props.area.backgrounds, time: v}})}
            />}
        </Container>
    )
}