import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { useStore, useAuth, useIsPortrait } from '../../hooks'
import { Text } from '../UI'
import { SBackground, SArrow } from './SearchBar'
import { INotification } from '../control-panel/Notifications'
import { CloseButton } from '../NewUI'

const SNotifications = styled.div<{ active: boolean, isPortrait: boolean }>`
    position: absolute;

    width: 20rem;
    bottom: ${p => p.active ? '-1rem' : 0};
    right: -8rem;
    transform: translateY(100%);
    transition: .3s;
    opacity: ${p => p.active ? 1 : 0};
    pointer-events: ${p => p.active ? 'all' : 'none'};

    display: grid;
    grid-gap: 1rem;
    padding: 1rem;

	${p => p.isPortrait && css`
		position: fixed;
		height: 50vh;
		width: 80vw;
		top: 4rem;
		left: 1rem;
		right: 1rem;
		transform: translate(0);
	`}
`

const SGridHolder = styled.div`
    height: 18rem;
    overflow: auto;
`

const SGrid = styled.div`
    display: grid;
    grid-gap: .3rem;
    height: min-content;
`

export default function Notifications(props: { active: boolean }) {
	const [store, dispatch] = useStore()
	const auth = useAuth()
	const [counterNotification, setCounterNotification] = useState(0)
	const isPortrait = useIsPortrait()

	if (auth?.user && !auth.user.notifications) auth.user.notifications = {}

	// Get only unread notifications.
	function getNotifications(): INotification[] {
		try {
			return (Object.values(auth.user.notifications) as any[])
				.filter(n => {
					return !n.closed
				}).reverse()
		} catch { return [] }
	}

	// Add notification to read ones.
	function closeHandler(id: string): void {
		auth.user.notifications[id] = { id: id, closed: true }
		saveUser()
	}

	function saveUser(): void {
		dispatch({ type: 'set-user', payload: auth.user })
	}

	// Clean old notifications from user read ones.
	useEffect(() => {
		if (!auth.user) return
		const userKeys = Object.keys(auth.user.notifications)
		for (const key of userKeys) {
			if (!(key in store.notifications)) delete auth.user.notifications[key]
		}
		saveUser()
	}, [])

	useEffect(() => {
		if (!auth.user) return

		if (props.active) {
			const allNewNotificationsIds = Object.keys(store.notifications).filter((id) => { return !(id in auth.user.notifications) })
			for (const id of allNewNotificationsIds) {
				auth.user.notifications[id] = { id: id, closed: false }
			}

			saveUser()
		}
	}, [props.active])

	useEffect(() => {
		if (!auth.user?.notifications) return
		if (!store.notifications) return

		const allNewNotifications = (Object.values(store.notifications) as INotification[]).filter(n => { return !(n.id in auth.user.notifications) })
		setCounterNotification(allNewNotifications.length)
	}, [store.notifications, auth.user?.notifications])

	return (
		<>
			{counterNotification > 0 && <SCounterNoty>{counterNotification}</SCounterNoty>}
			<SNotifications active={props.active} isPortrait={isPortrait}>
				<SBackground>
					<SArrow right='8.7rem' />
				</SBackground>

				<Text H2 bold>NOTIFICACIONES</Text>
				<SGridHolder>
					<SGrid>
						{
							getNotifications().map(n => <Tag key={n.id} notification={n} closeHandler={() => closeHandler(n.id)} />)
						}
					</SGrid>
				</SGridHolder>
			</SNotifications>
		</>
	)
}

const STag = styled.div`
    position: relative;
    background-color: ${p => p.theme.light};
    border-radius: ${p => p.theme.roundCorners};
    padding: 1rem;
`

function Tag(props: { notification: INotification, closeHandler(): void }) {
	const [store, dispatch] = useStore()
	return (
		<STag>
			<CloseButton
				onClick={() => props.closeHandler()}
			/>
			<Text dark H2>{store.notifications && store.notifications[props?.notification?.id]?.text}</Text>
		</STag>
	)
}

const SCounterNoty = styled.div`
	position: absolute;
	background: red;
	color: white;
	border-radius: 3px;
	left: 30px;
	top: -4px;
	padding: 0px 4px 0px 4px;
`
