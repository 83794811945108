import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { URLs } from '../../context/constants'
import { useHistory } from 'react-router-dom'
import { useStore, useIsPortrait } from '../../hooks/'
import { SBackground, SArrow } from './SearchBar'
import { Inputfield } from '../NewUI'
import icons from '../../graphics/icons'
import { TextIcon, Text } from '../UI'
import PoweredBy from '../PoweredBy'

const SMenu = styled.div<{ active: boolean }>`
    position: absolute;

    width: 18rem;
    height: 25rem;
    bottom: ${p => p.active ? '-.7rem' : 0};
    left: .1rem;
    transform: translateY(100%);
    transition: .3s;
    opacity: ${p => p.active ? 1 : 0};
    pointer-events: ${p => p.active ? 'all' : 'none'};

    display: grid;
    grid-template-rows: min-content auto;
    grid-gap: 1rem;
    padding: .6rem;
`

export const SGridHolder = styled.div`
    overflow: auto;
    overflow-x: hidden;
`

export const SGrid = styled.div`
    display: grid;
    grid-gap: .7rem;
    height: min-content;
`

const SCollapsable = styled.div<{ show: boolean }>`
    display: grid;
    transform-origin: top;
    ${p => !p.show && css`
        transform: scaleY(0);
        height: 0;
    `}
    box-sizing: border-box;
    overflow: hidden;
    opacity: ${p => p.show ? 1 : 0};
    transition: .3s;
    grid-gap: 1rem;
    padding-left: 2.7rem;
`

interface IState {
	search: string,
	showAreas?: boolean, // Control the collapsable state of areas.
	showPavillions?: boolean, // Control the collapsable state of pavillions.
	showVideos?: boolean // Control the collapsable state of video rooms.
}

export default function Menu(props: { active: boolean, close(): void }) {
	const [store] = useStore()
	const history = useHistory()
	const isPortrait = useIsPortrait()
	const [state, set] = useState<IState>({
		search: '',
		showAreas: true,
		showPavillions: true,
		showVideos: true
	})

	// Clear search bar.
	useEffect(() => {
		set(p => ({ ...p, search: '' }))
	}, [props.active])

	// Go to selected page and close the menu.
	function goTo(to: string): void {
		history.push(to)
		props.close()
	}

	let areas = []
	let pavillions = []
	let videoRooms = []

	// Get a sorted list of all available areas and map it to buttons.
	if (store.areas) {
		areas = (Object.values(store.areas) as any[])
			.sort((a, b) => a?.order > b?.order ? 1 : -1)
			.filter(a => a.id != 'home' && a.visibleInMenu)
			.filter(a => {
				if (state.search == '') return true
				return a.name.toLowerCase().includes(state.search.toLowerCase())
			})
			.map(a => <Text
				bold
				subtext
				onClick={() => goTo(`${URLs.area}?id=${a.id}`)}>{a.name}</Text>)
	}

	// Get a sorted list of all available pavillions and map it to buttons.
	if (store.pavillions) {
		pavillions = (Object.values(store.pavillions) as any[])
			.sort((a, b) => a?.order > b?.order ? 1 : -1)
			.filter(a => {
				if (!a.visibleInMenu) return false
				if (state.search == '') return true
				return a.name.toLowerCase().includes(state.search.toLowerCase())
			})
			.map(a => <Text
				bold
				subtext
				onClick={() => goTo(`${URLs.pavillion}?id=${a.id}`)}>{a.name}</Text>)
	}

	// Get a sorted list of all available video rooms and map it to buttons.
	if (store.videoRooms) {
		videoRooms = (Object.values(store.videoRooms) as any[])
			.sort((a, b) => a?.order > b?.order ? 1 : -1)
			.filter(a => {
				if (!a.visibleInMenu) return false
				if (state.search == '') return true
				return a.name.toLowerCase().includes(state.search.toLowerCase())
			})
			.map(a => <Text
				bold
				subtext
				onClick={() => goTo(`${URLs.videoRoom}?id=${a.id}`)}>{a.name}</Text>)
	}

	return (
		<SMenu active={props.active}>
			<SBackground>
				<SArrow left='1.2rem' />
			</SBackground>

			{isPortrait && <PoweredBy />}

			<Inputfield
				onChange={v => set(p => ({ ...p, search: v }))}
				value={state.search}
				placeholder='Buscar'
				icon={icons.lupa}
			/>

			<SGridHolder>
				<SGrid>
					{store?.areas?.home.visibleInMenu && <TextIcon onClick={() => goTo('')} image={icons.home}>Inicio</TextIcon>}
					{store?.areas?.home.visibleInMenu && <div />}

					{areas.length != 0 && <TextIcon onClick={() => set(p => ({ ...p, showAreas: !p.showAreas }))} image={icons.areas}>Áreas</TextIcon>}
					{areas && <SCollapsable show={state.showAreas || state.search}>
						<div />
						{areas}
						<div />
					</SCollapsable>}

					{pavillions.length != 0 && <TextIcon onClick={() => set(p => ({ ...p, showPavillions: !p.showPavillions }))} image={icons.pavillion}>Pabellones</TextIcon>}
					{pavillions && <SCollapsable show={state.showPavillions || state.search}>
						<div />
						{pavillions}
						<div />
					</SCollapsable>}

					{videoRooms.length != 0 && <TextIcon onClick={() => set(p => ({ ...p, showVideos: !p.showVideos }))} image={icons.video}>Salas de Video</TextIcon>}
					{videoRooms && <SCollapsable show={state.showVideos || state.search}>
						<div />
						{videoRooms}
						<div />
					</SCollapsable>}
				</SGrid>
			</SGridHolder>
		</SMenu>
	)
}
