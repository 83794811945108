import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useHistory, Redirect, withRouter } from 'react-router-dom'
import styled from 'styled-components'

import TopBar from './components/TopBar'
import Popup from './components/Popup'
import UserMoreInfo from './components/UserMoreInfo'
import VideoCall from './components/VideoCall'
import Notifications from './components/Notifications'
import Viewport from './components/Viewport'
import TrackUser from './components/TrackUser'
import MultipleLoginAlert from './components/MultipleLoginAlert'
import Catalog from './components/topbar/Catalog'
import Product from './components/topbar/Product'
import VerifyEmail from './components/overlays/VerifyEmail'
import CompanyPoster from './components/overlays/CompanyPoster'

import { URLs } from './context/constants'

// Pages
import Home from './pages/Home'
import Area from './pages/Area'
import Pavillion from './pages/Pavillion'
import VideoRoom from './pages/VideoRoom'
import Stand from './pages/Stand'
import ControlPanel from './pages/ControlPanel'
import AreaEditor from './pages/AreaEditor'
import Playground from './pages/Playground'

// Contexts that require router history.
import { AnalyticsContext } from './context/AnalyticsContext'
import { TrackUserContext } from './context/TrackUserContext'

const Background = styled.div`
    background-color: ${p => p.theme.dark};
    position: fixed;
    z-index: -5;
    width: 100vw;
    height: 100vh;
`

function App(props) {
    return (
        <Router>
        <AnalyticsContext>
		<TrackUserContext>

            <Background />
            <TopBar />
            <Popup />
            <UserMoreInfo />
            <VideoCall />
            <Notifications />
            <Viewport />
            <MultipleLoginAlert />
			<TrackUser />
			<Catalog />
			<Product />
			<VerifyEmail />
			<CompanyPoster />

            <Switch>
                <Route path={URLs.home} exact component={withRouter(Home)} />
                <Route path={URLs.area} component={withRouter(Area)} />
                <Route path={URLs.pavillion} component={withRouter(Pavillion)} />
                <Route path={URLs.videoRoom} component={withRouter(VideoRoom)} />
                <Route path={URLs.stand} component={withRouter(Stand)} />
                <Route path={URLs.controlPanel} component={withRouter(ControlPanel)} />
                <Route path={URLs.areaEditor} component={withRouter(AreaEditor)} />
                <Route path='/playground' component={withRouter(Playground)} />
            </Switch>

		</TrackUserContext>
        </AnalyticsContext>
        </Router>
    )
}

export default App;