import menuIcon from './menu.svg'
import profileIcon from './profile.svg'
import editIcon from './edit.svg'
import notificationIcon from './notification.svg'
import cartIcon from './cart.svg'
import messageIcon from './message.svg'
import lupaIcon from './lupa.svg'
import homeIcon from './home.svg'
import areasIcon from './areas.svg'
import pavillionIcon from './pavillion.svg'
import videoIcon from './video.svg'
import crossIcon from './cross.svg'
import exitIcon from './exit.svg'
import gearIcon from './gear.svg'
import plusIcon from './plus.svg'
import backIcon from './back.svg'
import sendIcon from './send.svg'
import cameraIcon from './camera.svg'
import emailIcon from './email.svg'
import downloadIcon from './download.svg'
import newChatIcon from './newChat.svg'
import peopleIcon from './people.svg'
import subtractIcon from './subtract.svg'
import facebookIcon from './facebook.svg'
import instagramIcon from './instagram.svg'
import twitterIcon from './twitter.svg'
import linkedinIcon from './linkedin.svg'
import youtubeIcon from './youtube.svg'
import whatsappIcon from './whatsapp.svg'
import chatIcon from './chat.svg'
import websiteIcon from './web-programming.svg'
import standIcon from './stand.svg'
import privacyIcon from './privacy.svg'
import facebookColorIcon from './facebook-color.svg'
import googleColorIcon from './google-color.svg'
import infoIcon from './info.svg'
import checkmarkIcon from './checkmark.svg'
import warningIcon from './warning.svg'
import gameIcon from './game.svg'
import monitorIcon from './monitor.svg'
import phoneIcon from './phone.svg'
import calendarIcon from './calendar.svg'
import flyerIcon from './flyer.svg'

const icons = {
	menu: menuIcon,
	profile: profileIcon,
	edit: editIcon,
	notification: notificationIcon,
	cart: cartIcon,
	message: messageIcon,
	lupa: lupaIcon,
	home: homeIcon,
	areas: areasIcon,
	pavillion: pavillionIcon,
	video: videoIcon,
	cross: crossIcon,
	exit: exitIcon,
	gear: gearIcon,
	plus: plusIcon,
	back: backIcon,
	send: sendIcon,
	camera: cameraIcon,
	email: emailIcon,
	downlaod: downloadIcon,
	newChat: newChatIcon,
	people: peopleIcon,
	subtract: subtractIcon,
	facebook: facebookIcon,
	instagram: instagramIcon,
	twitter: twitterIcon,
	linkedin: linkedinIcon,
	youtube: youtubeIcon,
	whatsapp: whatsappIcon,
	chat: chatIcon,
	web: websiteIcon,
	stand: standIcon,
	privacy: privacyIcon,
	googleColor: googleColorIcon,
	facebookColor: facebookColorIcon,
	info: infoIcon,
	checkmark: checkmarkIcon,
	warning: warningIcon,
	game: gameIcon,
	monitor: monitorIcon,
	phone: phoneIcon,
	calendar: calendarIcon,
	flyer: flyerIcon,
}

export default icons
