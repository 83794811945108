import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useTrackUser, useAuth, useStore, useUserMoreInfo, useParam, useOutsideAlerter, useIsPortrait } from '../hooks'
import { ITrackUser } from '../context/TrackUserContext'
import { URLs } from '../context/constants'

import { SBackground, SArrow, SGrid, SGridHolder } from './topbar/SearchBar'
import { Text, SVG, Image } from '../components/UI'
import icons from '../graphics/icons'

const STrackUser = styled.div<{ active: boolean }>`
	pointer-events: none;
	display: ${p => p.active ? 'block' : 'none'};
	position: fixed;
	width: 100vw;
	height: 100vh;

	z-index: 2;
`

const SPosition = styled.div`
	position: absolute;
	left: 17rem;
	top: 0.50rem;
`

const SDisplay = styled.div`
	pointer-events: all; // TODO REMOVE
	border-radius: ${p => p.theme.roundCorners};
	background-color: ${p => p.theme.primary};
	display: grid;
	grid-template-columns: 2rem auto;
	height: 2rem;
	padding: 0 .5rem;
	grid-gap: .5rem;
	cursor: pointer;
	height: 2.5rem;
`

interface IState {
	panel: boolean,
}

export default function TrackUser() {
	const [state, set] = useState<IState>({
		panel: false,
	})
	const [store] = useStore()
	const trackUser = useTrackUser() as ITrackUser
	const auth = useAuth()
	const id = useParam('id')
	const menuRef = useRef()
	const isPortrait = useIsPortrait()

	useOutsideAlerter(menuRef, () => {
		set(p => ({ ...p, panel: false }))
	})

	// Check if this user can see data.
	function getIsActive(): boolean {
		if (isPortrait) return false

		const path = window.location.pathname

		if (!auth?.user?.id || !store?.configurations?.trackUsers) return false

		if (auth.type === 'organizer') return store?.configurations?.featureTrackUsers

		if (auth.user.type === 'normal') return false

		if (auth.user.type === 'expositor') {
			if (path != URLs.stand || !store?.configurations?.efeatureTrackUsers) return false
			try {
				return store.stands[id].company === auth.user.typeData.company
			} catch { return false }
		}

		return true
	}

	return (
		<STrackUser ref={menuRef} active={getIsActive()}>
			<SPosition>
				<SDisplay onClick={() => set(p => ({ ...p, panel: !p.panel }))}>
					<SVG image={icons.people} contain />
					<Text H2 bold middle>{trackUser.getCount()}</Text>
				</SDisplay>
				<Users userIds={trackUser.getIds()} active={state.panel} />
			</SPosition>
		</STrackUser>
	)
}

const SUsers = styled.div`
    position: absolute;

    width: 15rem;
    bottom: ${p => p.active ? '-.7rem' : '0'};
    left: 0;
    opacity: ${p => p.active ? 1 : 0};
    pointer-events: ${p => p.active ? 'all' : 'none'};
    transform: translateY(100%);
    transition: .3s;

    display: grid;
    grid-template-rows: min-content 20rem;
    grid-gap: 1rem;
    padding: 1.5rem 1rem;
`

const STitle = styled.div`
	display: grid;
	grid-template-columns: auto 2rem;
`

function Users(props: { userIds: string[], active: boolean }) {
	const [store] = useStore()

	// Get current area's user items.
	function getUsers(): any[] {
		if (!store.users) return []

		try {
			return props.userIds
				.map(id => store.users[id])
				.filter(user => user != undefined)
				.map(user => <UserItem key={user.id} user={user} />)
		} catch { }
	}

	return (
		<SUsers active={props.active}>
			<SBackground>
				<SArrow left='1.2rem' />
			</SBackground>
			<STitle>
				<Text bold H2 oneline>USUARIOS EN LA SALA</Text>
				<SVG image={icons.people} contain />
			</STitle>
			<SGridHolder>
				<SGrid>
					{getUsers()}
				</SGrid>
			</SGridHolder>
		</SUsers>
	)
}

const SUserItem = styled.div`
	display: grid;
	height: 2rem;
	grid-template-columns: 2rem auto;
	grid-gap: 1rem;
	cursor: pointer;
	transition: .2s;
	:hover {
		transform: translateX(.3rem);
	}
`

const SUserName = styled.div`
	display: grid;
`

const SProfile = styled.div`
	border-radius: 50%;
`

function UserItem(props: { user: any }) {
	const [usersMoreInfo] = useUserMoreInfo()

	return (
		<SUserItem onClick={() => usersMoreInfo.setUser(props.user)}>
			<SProfile>
				{props.user.profilePicture ?
					<Image contain url={props.user.profilePicture} /> :
					<SVG contain image={icons.profile} />
				}
			</SProfile>
			<SUserName>
				<Text H2 bold oneline>{props.user.name} {props.user.lastName}</Text>
				<Text H3 medium>{props.user.email}</Text>
			</SUserName>
		</SUserItem>
	)
}
