import React, { useState } from 'react'
import styled from 'styled-components'
import { key } from 'firebase-key'

import { uploadImage } from '../../../firebase/storage'
import { Button, Text, InputField, IconButton, UploadFile, Image } from '../../UI'
import { useNotifications } from '../../../hooks'

import closeIcon from '../../../graphics/icons/arrow-left.svg'

interface Dictionary<T> { [Key: string]: T; }

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`

const Panel = styled.div`
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
`

const GridContainer = styled.div`
    overflow: auto;
    height: 20rem;
`

const GridPanel = styled.div`
    display: grid;
    grid-gap: 1rem;
`

const ItemContainer = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    height: 2rem;
    padding: 0 1rem;
    background-color: ${p => p.theme.medium};
    :hover {
        background-color: ${p => p.theme.primary};
    }
    cursor: pointer;
`

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`

const ImageContainer = styled.div`
    height: 20rem;
    border: .1rem solid ${p => p.theme.light};
`

export interface IItem {
    id: string,
    name?: string,
    description?: string,
    price?: string,
    image?: string
}

interface IProps {
    stand: { id: string, name?: string, catalog?: Dictionary<IItem> }
    backHandler(): void,
    saveStand(stand): void,
}

interface IState {
    selected?: IItem
    searchValue: string,
    image?: any,
    loading: boolean
}

export default function Catalog({ stand, saveStand, backHandler }: IProps) {
    const [state, set] = useState<IState>({ 
        searchValue: '',
        loading: false,
    })
    const notifications = useNotifications() as any

    // Upload image to storage.
    const uploadItemImage = async () => {
        const image = state.image
        set(prev => { return {...prev, image: undefined, loading: true }})
        try {
            const id = await uploadImage(image)

            set(prev => { return { ...prev, selected: { ...prev.selected, image: id }}})
        } catch(error) { notifications.notify(error) }

        set(prev => { return { ...prev, loading: false }})
    }

    // Set selected item for editing.
    function selectItem(item: IItem): void {
        set(prev => { return { ...prev, selected: item }})
    }

    // Create new item.
    function newItemHandler(): void {
        if (!stand.catalog) stand.catalog = {}
        const newItem: IItem = { id: key() }
        stand.catalog[newItem.id] = newItem
        saveStand(stand)
        selectItem(newItem)
    }

    function deleteItem(): void {
        if (!state?.selected?.id) return
        delete stand.catalog[state.selected.id]
        selectItem(undefined)
        saveStand(stand)
    }

    // Saves Item to store.
    function saveItem(): void {
        if (!state?.selected?.id) return
        stand.catalog[state.selected.id] = state.selected
        saveStand(stand)
    }

    return (
        <Container>
            <CloseButton><IconButton onClick={backHandler} image={closeIcon}/></CloseButton>
            <Panel>
                <Text H1 bold>Catálogo de {stand.name}</Text>
                <div />
                <div />
                {!stand.catalog && <Text H2 center bold>No hay productos...</Text>}
                {stand.catalog && <InputField 
                    value={state.searchValue}
                    placeholder='buscar item...'
                    onChange={v => set(prev => { return { ...prev, searchValue: v }})}
                />}

                <div />

                {stand.catalog &&
                    <GridContainer>
                        <GridPanel>
                            {(Object.values(stand.catalog) as IItem[])
                                .filter(item => {
                                    if (state.searchValue === '') return true
                                    if (!item.name) return false
                                    return item.name.toLowerCase().includes(state.searchValue.toLocaleLowerCase())
                                })
                                .map(item => 
                                <ItemContainer onClick={() => selectItem(item)}>
                                    <Text middle bold H3>{item.name || 'Sin nombre'}</Text>
                                    <Text middle bold H3>${item.price || '0'}</Text>
                                </ItemContainer>)}
                        </GridPanel>
                    </GridContainer>
                }

                <div />
                <div />

                <Button style={{height: '2rem'}} onClick={() => newItemHandler()}>Nuevo Producto</Button>
            </Panel>
            {state.selected && <Panel>
                <Text H1 bold>Editando: {state.selected.name}</Text>
                <div />
                <InputField 
                    placeholder='Nombre'
                    value={state?.selected?.name || ''}
                    onChange={v => set(prev => { return { ...prev, selected: { ...prev.selected, name: v }}})}
                />
                <InputField 
                    placeholder='Descripción'
                    value={state?.selected?.description || ''}
                    onChange={v => set(prev => { return { ...prev, selected: { ...prev.selected, description: v }}})}
                />
                <InputField 
                    placeholder='Precio'
                    value={state?.selected?.price || ''}
                    onChange={v => set(prev => { return { ...prev, selected: { ...prev.selected, price: v }}})}
                />

                <ImageContainer> 
                    {state.selected.image && <Image url={state.selected.image} contain/>}
                </ImageContainer>

                {state.loading ? <Text H2 bold>Subiendo...</Text> : <UploadFile style={{height: '2rem'}} fileHanlder={file => set(prev => { return {...prev, image: file }})}>Subir nueva imagen</UploadFile>}
                {state.image && <Button onClick={() => uploadItemImage()} style={{height: '2rem'}}>Subir</Button>}

                <div />
                <Button style={{height: '2rem'}} onClick={() => saveItem()}>Guardar</Button>
                <Button style={{height: '2rem'}} onClick={() => deleteItem()}>Eliminar</Button>
            </Panel>}
        </Container>
    )
}