import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStore, useLanguage, useAuth } from '../../hooks'
import { Button, InputField } from '../UI'
import { MultiDropdown, CheckBox } from '../NewUI'
import { Feedback } from './'

interface Dictionary<T> { [key: string]: T }

const SFormView = styled.div`
	height: 30rem;
    display: grid;
	grid-template-rows: auto min-content;
	grid-gap: 1rem;
`

const SGridHolder = styled.div`
	overflow: auto;
	overflow-x: hidden;
`

const SGrid = styled.div`
    height: min-content;
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

interface IState {
	name: string,
	lastName: string,
	extraInfo: {},
	aditionalLink: string,
}

export default function FormView(props: { feedback?: string, isEdit?: boolean, confirmHandler(name: string, lastName: string, extraInfo: {}, aditionalLink: string): void }) {
	const language = useLanguage() as any
	const [state, set] = useState<IState>({
		name: '',
		lastName: '',
		extraInfo: {},
		aditionalLink: '',
	})
	const [store] = useStore()
	const auth = useAuth()

	// Set current user values if is editing.
	useEffect(() => {
		if (props.isEdit && auth?.user) {
			set(p => ({
				...p,
				name: auth?.user?.name ?? '',
				lastName: auth?.user?.lastName ?? '',
				extraInfo: auth?.user?.extraInfo ?? {},
				aditionalLink: auth?.user?.aditionalLink ?? '',
			}))
		}
	}, [props.isEdit])

	function confirmHandler(): void {
		if (state.name === '' || state.lastName === '') {
			// Feedback
			return
		}

		// TODO check extra required data.

		props.confirmHandler(state.name, state.lastName, state.extraInfo, state.aditionalLink)
	}

	function getExtraData() {
		interface IOption {
			id: string,
			name: string,
			value: boolean
		}
		if (!store?.configurations?.userExtraInfo) return []

		return (Object.values(store.configurations.userExtraInfo) as any[])
			.sort((a, b) => {
				const av = a.order ? parseFloat(a.order) : Number.MAX_SAFE_INTEGER
				const bv = b.order ? parseFloat(b.order) : Number.MAX_SAFE_INTEGER
				return av > bv ? 1 : -1
			})
			.map(info => {
				if (info.type === 'boolean') return (
					<CheckBox
						onClick={() => {
							state.extraInfo[info.id] = !(state.extraInfo[info.id] ? state.extraInfo[info.id] : false)
							set({ ...state })
						}}
						title={info.name}
						value={state?.extraInfo ? state.extraInfo[info.id] : false}
					/>
				)

				else if (info.type === 'dropdown' && info.options) return (
					<MultiDropdown
						singleOption={!info.multi}
						title={info.name}
						options={Object.values(info.options).reduce((obj: Dictionary<IOption>, option: IOption) => ({
							...obj,
							[option.id]: {
								id: option.id,
								name: option.name,
								value: (state?.extraInfo && state?.extraInfo[info.id]) ? option.id in state?.extraInfo[info.id] : false
							}
						}), {}) as Dictionary<IOption>}
						clickHandler={id => {
							if (!state.extraInfo) state.extraInfo = {}
							if (!state.extraInfo[info.id]) state.extraInfo[info.id] = {}

							if (id in state.extraInfo[info.id]) delete state.extraInfo[info.id][id]
							else state.extraInfo[info.id][id] = id

							set({ ...state })
						}}
						style={{ width: '16rem' }}
					/>)

				else return (<InputField
					key={info.id}
					title={info.required ? info.name + ' *' : info.name}
					value={state.extraInfo[info.id]}
					onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					onChange={v => {
						state.extraInfo[info.id] = v
						set({ ...state })
					}}
				/>)
			})
	}

	return (
		<SFormView>
			<SGridHolder>
				<SGrid>
					<InputField
						title={`${language.getText('Nombre')} *`}
						value={state.name}
						onChange={v => set(p => ({ ...p, name: v }))}
						onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					/>
					<InputField
						title={`${language.getText('Apellido')} * `}
						value={state.lastName}
						onChange={v => set(p => ({ ...p, lastName: v }))}
						onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					/>
					{getExtraData()}
					<InputField
						title={`${language.getText('Link Adicional')}`}
						value={state.aditionalLink}
						onChange={v => set(p => ({ ...p, aditionalLink: v }))}
						onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					/>

					{props.feedback && <Feedback feedback={props.feedback} />}
				</SGrid>
			</SGridHolder>
			<Button onClick={confirmHandler}>{language.getText(props.isEdit ? 'Guardar' : 'Crear Usuario')}</Button>
		</SFormView>
	)
}
