import React, { useEffect } from 'react'
import { logIn, trackAuthState, register, logOut as firebaseLogOut, googleLogin, facebookLogin } from '../firebase/auth'
import { getContent, setContent, onDisconnect, subscribeToContent } from '../firebase/database'
import { databasePaths } from '../firebase'
import { logEvent } from '../firebase/analytics'
import { usePopup, useElementsManager, useLanguage } from '../hooks'
import useState from 'react-usestateref'

const Auth = React.createContext()

const debug = false

const debugUser = {
    name: 'debug User',
    type: 'admin',
}

const admins = [
    'mateo@mateo.com',
    'project1@bigjonan.com.ar',
    'horacio@horacio.com',
    'wilfredo.s@bigjonan.com.ar',
    'ventas3@bigjonan.com.ar',
    'karinabas@hotmail.com',
	'ventas2@bigjonan.com.ar',
]

export function AuthContext(props) {
    const elementsManager = useElementsManager()
    const [tokenRefresh, setTokenRefresh, tokenRefreshRef] = useState({
        init: true,
        token: ''
    })
    const [state, setState, stateRef] = useState({
        user: debug ? debugUser : null,
        isAdmin() {
            if (!this.user) return false
            return this.user.type === 'admin'
        },
        isOrganizer() {
            if (!this.user) return false
            return this.user.type === 'organizer'
        },
        isExpositor() {
            if (!this.user) return false
            return this.user.type === 'expositor'
        },
        editUser: u => setState({ ...state, user: u }),
        editLanguage: l => setState({ ...state, language: l }),
        logIn: (email, password) => new Promise((resolve, reject) => {
            logIn(email, password).then(u => { setState({ ...state, loginCallback: resolve }) }).catch(e => reject(e))
        }),
        googleLogin: () => new Promise((resolve, reject) => {
            setState({ ...state, loginCallback: resolve })
            googleLogin()
        }),
        facebookLogin: () => new Promise((resolve, reject) => {
            setState({ ...state, loginCallback: resolve })
            facebookLogin()
        }),
        loginCallback: u => { },
        logOut() {
            if (this.user && this.user.id) {
                setContent(`${databasePaths.users}/${this.user.id}/online`, false)
                logEvent('logOut', this.user.email)
            }

            firebaseLogOut()
            setState(prev => { return { ...prev, isTrackingAuthState: false, user: null } })
        },
        register: (email, password, name, lastName, extraInfo, aditionalLink) => new Promise(async (resolve, reject) => {
            try {
                const response = await register(email, password)
                const user = {
                    id: response.user.uid,
                    email,
                    type: 'normal',
                    name,
                    lastName,
                    extraInfo,
					aditionalLink
                }

                const delay = new Promise((resolve, reject) => {
                    setTimeout(resolve, 1000)
                })
                await delay

                await setContent(`${databasePaths.users}/${user.id}`, user)

                await state.logIn(email, password)

                logEvent('register', email)
                resolve(user)
            } catch (error) { reject(error) }
        }),
        isTrackingAuthState: false,
    })
    const [popup] = usePopup()
    const language = useLanguage()

    useEffect(() => {
        let cancelation = () => { }
        if (state.user && state.user.id) {
            const path = `${databasePaths.users}/${state.user.id}/online`
            cancelation = subscribeToContent(path, v => {
                setTimeout(() =>
                    setState(prev => {
                        if (!v && prev.user) setContent(path, true)
                        return { ...prev }
                    }), 100)
            })
        }

        // Set language.
        if (stateRef.current?.user && stateRef.current.language && stateRef.current.language != stateRef.current.user.language) {
            stateRef.current.editUser({ ...stateRef.current.user, language: stateRef.current.language })
        }
        else if (stateRef.current?.user?.language) {
            // language.selectLanguage(stateRef.current.user.language)		
        }

        return cancelation
    }, [state.user])

    useEffect(() => {
        if (state?.user?.banFromNavegation) popup.blockScreen('Su cuenta ha sido desactivada, comunicarse con la organización.', true)
        else if (popup.active) popup.blockScreen('a', false)
    }, [state?.user?.banFromNavegation])

    useEffect(() => {
        async function handleStateChange(localUser) {
            if (localUser) {
                let user = await getContent(`${databasePaths.users}/${localUser.uid}`)

                if (!user) {
                    user = {
                        type: 'normal',
                        email: localUser.email,
                        id: localUser.uid
                    }

                    setContent(`${databasePaths.users}/${localUser.uid}`, user)
                }

                else if (!user.email) {
                    user.email = localUser.email
                    setContent(`${databasePaths.users}/${localUser.uid}`, user)
                }

                user.emailVerified = localUser.emailVerified

                // Save creation time to user database.
                if (!user.creationTime && localUser?.metadata?.creationTime) {
                    user.creationTime = new Date(localUser?.metadata?.creationTime).toLocaleDateString()
                    setContent(`${databasePaths.users}/${localUser.uid}`, user)
                }

                const onlinePath = `${databasePaths.users}/${localUser.uid}/online`

                setState({ ...state, user: user, isTrackingAuthState: true })
                logEvent('logIn', user.email)

                state.loginCallback(user)

                // Set Online.
                setContent(onlinePath, true)

                // Set offline on disconnect.
                onDisconnect(onlinePath, false)
                onDisconnect(`${databasePaths.users}/${localUser.uid}/onACall`, false)

                setTokenRefresh(p => ({ ...p, token: localUser.refreshToken, init: true }))
            }

            else {
                setState({ ...state, user: null, isTrackingAuthState: true })
            }
        }

        if (!state.isTrackingAuthState) {
            trackAuthState(handleStateChange)
        }
    }, [state.isTrackingAuthState])

    useEffect(() => {
        let cancelAuthTokenSubscription = () => { }
        if (tokenRefreshRef.current.token && tokenRefreshRef.current.token != '' && stateRef.current?.user?.id) {
            // Set auth token.
            const authTokenPath = `${databasePaths.users}/${stateRef.current.user.id}/authToken`
            setContent(authTokenPath, tokenRefreshRef.current.token)

            // Check if auth token changes and if so, logout.
            cancelAuthTokenSubscription = subscribeToContent(authTokenPath, token => {
                if (tokenRefreshRef.current.init) setTokenRefresh(p => ({ ...p, init: false }))
                else if (token && token != tokenRefreshRef.current.token && stateRef.current?.user?.id) elementsManager.blockUser()
            })
        }

        return () => cancelAuthTokenSubscription()

    }, [state?.user?.id])

    useEffect(() => {
        let userPingInterval
        let cancelUserSubscription = () => { }

        function logPin() { if (stateRef?.current?.user?.id) setContent(`${databasePaths.users}/${stateRef.current.user.id}/lastPing`, Date.now()) }

        if (stateRef?.current?.user?.id) {
            logPin()
            userPingInterval = setInterval(logPin, 600000)
            cancelUserSubscription = subscribeToContent(`${databasePaths.users}/${stateRef.current.user.id}`, user => {
                if (stateRef?.current?.user?.id) setState(prev => { return { ...prev, user: user } })
            })

            // Auto Admins.
            if (admins.includes(stateRef?.current?.user?.email) && stateRef.current.user.type != 'admin')
                setContent(`${databasePaths.users}/${stateRef.current.user.id}/type`, 'admin')
        }

        return () => {
            clearInterval(userPingInterval)
            cancelUserSubscription()
        }
    }, [state?.user?.id])

    return <Auth.Provider value={state}>{props.children}</Auth.Provider>
}

export default Auth