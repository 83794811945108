import React, { useState } from 'react'

const UserMoreInfo = React.createContext()

export function UserMoreInfoContext(props) {
    const [state, setState] = useState({
        user: {},
        setUser(user) {
            setState({...state, user: user})
        },
        close() {
            setState({...state, user: {}})
        }
    })

    return <UserMoreInfo.Provider value={[state, setState]}>{ props.children }</UserMoreInfo.Provider>
}

export default UserMoreInfo