import React, { useState, useEffect } from 'react'

const Search = React.createContext()

export function SearchContext(props) {
    const [state, setState] = useState({
        value: '',
    })

    return <Search.Provider value={[state, setState]}>{ props.children }</Search.Provider>
}

export default Search