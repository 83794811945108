import React, { useState, useEffect } from 'react'
import { useElementsManager } from '../hooks'

const Popup = React.createContext()

export function PopupContext(props) {
    const [state, setState] = useState({
        title: '',
        confirmAction: null,
        confirmText: 'Confirmar',
        cancelAction: null,
        cancelText: 'Cancelar',
        active: false,
        inputfield: '',
        type: 'inputfield',
        playholder: '',
        askForText: (title, onConfirm, placeholder, onCancel, confirmText='Enviar', cancelText='Cancelar') => { 
            setState(prev => { return {...prev,
                type: 'inputfield',
                title: title,
                placeholder: placeholder,
                confirmAction: () => {
                    setState(prev => {
                        onConfirm(prev.inputfield)
                        return {...prev, active: false, inputfield: ''}
                    })
                },
                cancelAction: () => {
                    setState(prev => {
                        if (onCancel) onCancel()
                        return {...prev, active: false, inputfield: ''}
                    })
                },
                confirmText: confirmText,
                cancelText: cancelText,
                active: true,
            }}
        )},
        isBlockingScreen: false,
        blockScreen(title, active) {
            setState(prev => { return {
                    ...prev,
                    isBlockingScreen: active,
                    active: active,
                    title: title
                }
            })
        }
    })
    const elementsManager = useElementsManager()

    useEffect(() => {
        elementsManager.setBoth(!state.active)
    }, [state.active])

    return <Popup.Provider value={[state, setState]}>{ props.children }</Popup.Provider>
}

export default Popup