import React, { useState } from 'react'
import styled from 'styled-components'
import { useStore } from '../../hooks'
import { Text, Button, Background, Image, FadeInUp, UploadFile, InputField, IconButton, SVG } from '../UI'
import closeIcon from '../../graphics/icons/arrow-left.svg'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import List from 'react-virtualized/dist/commonjs/List'

export default function Ranking() {
    const [state, set] = useState({
        view: 'ranking'
    })

    // Get UI view content.
    const getContent = () => {
        if (state.view === 'ranking') return <RankingList 
                selectRanking={r => set(prev => { return { ...prev, selected: r, view: 'users' }})}
            />
        if (state.view === 'users' && state.selected && state.selected?.users) return <UsersList 
            ranking={state.selected}
            goBack={() => set(prev => { return { ...prev, selected: undefined, view: 'ranking' }})}
        /> 
    }

    return getContent()
}

const ListContainer = styled.div`
    position: relative;
    padding: 1rem;
    height: 100%;
`

const TitleRow = styled.div`
    height: 3rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
`

function RankingList(props) {
    const [store] = useStore()
    const [state, setState] = useState({
        search: ''
    })

    // Get list of rankings filtered by search.
    const getList = () => {
        if (store.ranking) {
            return Object.values(store.ranking)
                .filter(r => (r.name.toLowerCase().includes(state.search.toLowerCase())))
                .map(r => <RankingTag onClick={ () => props.selectRanking(r) } key={r.id} ranking={r}/>)
        }
    }

    const list = getList()
    list.unshift(
        <TitleRow key='search'>
            <Text H1 bold>Ranking</Text>
            <InputField 
                value={state.search}
                onChange={v => setState({...state, search: v})}
                placeholder='Buscar ranking'
            />
        </TitleRow>)

    const rowRenderer = ({key, index, style}) => {
        return (
            <div key={key} style={style}>
                {list[index]}
            </div>
        )
    }

    return (
        <ListContainer>
        <AutoSizer>
            {
                ({height, width}) => (
                    <FadeInUp>
                    <List 
                        height={height}
                        rowCount={list ? list.length : 0}
                        rowHeight={64}
                        rowRenderer={rowRenderer}
                        width={width}
                    />
                    </FadeInUp>
                )
            }
        </AutoSizer>
        </ListContainer>
    )
}

const RankingTagContainer = styled.div`
    display: grid;
    grid-template-columns: auto 10rem;
    background-color: ${p => p.theme.light};
    height: 2rem;
    padding-left: 2rem;

    cursor: pointer;
`

const CounterContainer = styled.div`
    background-color: ${p => p.theme.primary};
`

function RankingTag(props) {

    // Count users that voted.
    function getCount() {
        try { return Object.values(props.ranking.users).length } catch { return '0' }
    }

    return (
        <RankingTagContainer onClick={() => props.onClick()}>
            <Text H2 bold middle dark>{props.ranking.name}</Text>
            <CounterContainer><Text bold H2 center middle>{getCount()}</Text></CounterContainer>
        </RankingTagContainer>
    )
}


const UserTagContainer = styled.div`
    display: grid;
    height: 2rem;
    border: .1rem solid ${p => p.theme.light};
    grid-template-columns: auto min-content;
    padding: 0 1rem;
`

const CloseButton = styled.div`
    position: absolute;
    top: -2.5rem;
    left: -2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 4;
`

function UsersList(props) {
    const [store] = useStore()
    const [state, setState] = useState({
        search: ''
    })

    // Get users filtered by search.
    const getList = () => {
        if (store.users) {
            return Object.values(store.users)
                .filter(u => u.id in props.ranking.users && u.email)
                .filter(u => (u.email?.toLowerCase().includes(state.search.toLowerCase())))
                .map(u => <UserTagContainer><Text bold H2 middle>{u.name || ''} {u.lastName || ''}</Text><Text bold H2 middle>{u.email}</Text></UserTagContainer>)
        }
    }

    const list = getList()
    list.unshift(
        <TitleRow key='search'>
            <Text H1 bold>{props?.ranking?.name}</Text>
            <InputField 
                value={state.search}
                onChange={v => setState({...state, search: v})}
                placeholder='Buscar usuario'
            />
        </TitleRow>)

    const rowRenderer = ({key, index, style}) => {
        return (
            <div key={key} style={style}>
                {list[index]}
            </div>
        )
    }

    return (
        <ListContainer>
        <CloseButton><IconButton onClick={() => props.goBack()} image={closeIcon}/></CloseButton>
        <AutoSizer>
            {
                ({height, width}) => (
                    <FadeInUp>
                    <List 
                        height={height}
                        rowCount={list ? list.length : 0}
                        rowHeight={64}
                        rowRenderer={rowRenderer}
                        width={width}
                    />
                    </FadeInUp>
                )
            }
        </AutoSizer>
        </ListContainer>
    )
}