import React, { useEffect } from 'react'
import styled from 'styled-components'

import { IconButton, Text } from '../components/UI'
import { useViewport } from '../hooks'

import { IViewport } from '../context/ViewportContext'

import backIcon from '../graphics/icons/close.svg'

const Container = styled.div<{active?: boolean}>`
    display: ${p => p.active ? 'block': 'none'};
    position: fixed;
    top: 0; bottom: 0; left: 0; right: 0;
    background-color: rgba(0, 0, 0, .8);
    z-index: 5;
`

const Panel = styled.div<{active?: boolean}>`
    position: absolute;
    width: 90%;
    height: 70%;
    background-color: ${p => p.theme.light};
    border-radius: ${p => p.theme.roundCorners};
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;

    opacity: ${p => p.active ? 1 : 0};
    top: ${p => p.active ? '50%' : '55%'};
    transition: 3s;
`

const TitleContent = styled.div`
    position: absolute;
    top: -1rem;
    left: 0;
    transform: translateY(-100%);
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
`

export default function Viewport() {
    const viewport = useViewport() as IViewport

    return (
        <Container active={viewport.state.active}>
            <Panel active={viewport.state.active}>
            <TitleContent>
                <IconButton style={{width: '2rem', height: '2rem'}} onClick={() => viewport.setActive(false)} image={backIcon}/>
                <Text middle bold H2>{viewport.state.title || ''}</Text>
            </TitleContent>

                {viewport.state.content}

            </Panel>
        </Container>
    )
}