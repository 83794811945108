import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { useStore, useAuth } from '../../hooks'
import { Text, Button } from '../UI'
import { getCurrentUser } from '../../firebase/auth'

const SVerifyEmail = styled.div<{ show: boolean }>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	background-color: ${p => p.theme.dark};
	z-index: 5;

	display: ${p => p.show ? 'block' : 'none'};
`

const SPanel = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 1rem;
	background-color: ${p => p.theme.light};
	border-radius: ${p => p.theme.roundCorners};

	display: grid;
	grid-gap: 1rem;
`

const SRow = styled.div`
	display: grid;
	height: 2rem;
	grid-gap: 1rem;
	grid-template-columns: 1fr 1fr;
`

export default function VerifyEmail() {
	const [store] = useStore()
	const auth = useAuth()
	const [show, setShow] = useState<boolean>(false)
	const [emailSended, setEmailSended] = useState<boolean>(false)

	useEffect(() => {
		if (!auth?.user || !store?.configurations?.requireEmailVerification) setShow(false)
		else if (auth?.user?.type === 'admin') setShow(false) // Admins get free pass.
		else setShow(!getCurrentUser()?.emailVerified)
	}, [getCurrentUser()?.emailVerified, store?.configurations?.requireEmailVerification])

	function sendVerificationEmail(): void {
		getCurrentUser().sendEmailVerification()
			.then(() => setEmailSended(true))
			.catch(error => { console.log(error) })
	}

	return (
		<SVerifyEmail show={show}>
			<SPanel>
				<Text oneline bold H2 dark center>Verificar Email</Text>
				<Text oneline bold H2 dark center>{auth?.user?.email ?? ''}</Text>
				<div />
				{emailSended && <Button style={{ height: '2rem' }} onClick={() => window.location.reload()}>Refrescar</Button>}
				<SRow>
					<Button onClick={() => auth.logOut()} gray>Cerrar sesión</Button>
					{emailSended ?
						<Text oneline middle bold H2 primary center>Email enviado correctamente</Text> :
						<Button onClick={() => sendVerificationEmail()}>Enviar email de verificación</Button>
					}
				</SRow>

			</SPanel>
		</SVerifyEmail>
	)
}
