import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { key } from 'firebase-key'

import { setContent } from '../firebase/database'
import { databasePaths } from '../firebase'

import { useShoppingList, useIsPortrait, useAuth, useStore, useCatalog, useNotifications } from '../hooks'
import { IShoppingList, IAddedItem } from '../context/ShoppingListContext'
import { IItem } from './control-panel/Stands/Catalog'
import { sendEmail } from '../utility'

import { Text, Button, Image, SVG } from './UI'
import deleteIcon from '../graphics/icons/delete.svg'
import closeIcon from '../graphics/icons/close.svg'

const Container = styled.div<{ active?: boolean, isPortrait?: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    width: 20rem;
    right: ${p => p.active ? 0 : '-20rem'};
	z-index: 1;

    ${p => p.isPortrait && css`
        width: 100vw;
        right: ${p.active ? 0 : '-100%'};
        padding-top: 2.5rem;
    `}

    background-color: rgba(0, 0, 0, .8);
    transition: .3s;

    display: grid;
    grid-template-rows: 6rem auto 3rem 3rem;
	padding: .5rem;
	box-sizing: border-box;
`

const Panel = styled.div`
    padding: 1rem;
    display: grid;
    border-bottom: .1rem solid ${p => p.theme.light}; 
    border-top: .1rem solid ${p => p.theme.light}; 
    overflow: auto;
`

const PanelGrid = styled.div`
    display: grid;
    grid-gap: 1rem;
    height: min-content;
`

const BackButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    background-color: ${p => p.theme.primary};
    :hover { background-color: ${p => p.theme.primaryHover}; }
    display: flex;
    * {
        margin: auto;
        width: 60%;
        height: 60%;
    }
`

export default function ShoppingList() {
	const shoppingList = useShoppingList() as IShoppingList
	const isPortrait = useIsPortrait()
	const auth = useAuth()
	const [catalog, setCatalog] = useCatalog()
	const [store] = useStore()
	const notifications = useNotifications() as any

	useEffect(() => {
		// Close shopping cart if all items are removed.
		if (!auth?.user?.shoppingList && shoppingList.state.active) shoppingList.toggle()
	}, [auth?.user?.shoppingList])

	function getItems() {
		const items = {}

		try {
			(Object.values(auth.user.shoppingList) as IAddedItem[]).forEach(addedItem => {
				try {
					if (store.stands[addedItem.standId].catalog[addedItem.itemId]) items[addedItem.id] = {
						id: addedItem.id,
						item: store.stands[addedItem.standId].catalog[addedItem.itemId],
						amount: addedItem.amount,
						stand: store.stands[addedItem.standId]
					}
				} catch { }
			})
		} catch { }

		return items
	}

	const items = getItems()

	function getTotalPrice(): number { // Return the total price of the cart.
		let count: number = 0;
		(Object.values(items) as any[]).forEach(item => { try { count += item.item.price * item.amount } catch { } })
		return count
	}

	const totalPrice = getTotalPrice()

	function getTotalAmount(): number { // Return the total amount of items.
		let count: number = 0;
		(Object.values(items) as any[]).forEach(item => { try { count += item.amount } catch { } })
		return count
	}

	const totalAmount = getTotalAmount()

	function registerPurchase(): void {
		interface ILog {
			id: string,
			user: string,
			stand: string,
			product: string,
			amount: number,
			timestamp: string,
		}

		// Foreach purchase, log it to database.
		(Object.values(items) as { item: IItem, amount: number, stand: any }[]).forEach(item => {
			const log: ILog = {
				id: key(),
				user: auth.user.id,
				stand: item.stand.id,
				product: item.item.id,
				amount: item.amount,
				timestamp: new Date(Date.now()).toString(),
			}

			setContent(`${databasePaths.purchaseHistory}/${log.id}`, log)
		})
	}

	function purchaseHandler(): void {
		if (!auth?.user?.email) return

		const from = store?.configurations?.cloudFunctionPrivateMessageEmail
		const pass = store?.configurations?.cloudFunctionPrivateMessageEmailPass

		{
			let text = 'Tu Compra\n\n';

			(Object.values(items) as { item: IItem, amount: number, stand: any }[]).forEach(item => {
				text += `Nombre: ${item.item.name}\nDescripción: ${item.item.description}\nPrecio: $ ${item.item.price}\n\nCantidad: ${item.amount}\n\n`
			})

			text += `\n\n\n Precio total: $ ${totalPrice}\n\nQueridos Asociados, todo producto/combo que en este mail figure con menor cantidad que la mínima establecida, será desestimado de su transfer. Muchas gracias.`

			// Send to buyer
			sendEmail([auth.user.email], { subject: 'Compra', text, from, pass })
		}

		{
			const companies = {};

			(Object.values(items) as { item: IItem, amount: number, stand: any }[]).forEach(item => {
				if (item?.stand?.company) {
					if (!companies[item.stand.company]) companies[item.stand.company] = []
					companies[item.stand.company].push(item)
				}
			})

			Object.keys(companies).forEach(company => {
				let text = `Compra de usuario:\nNombre: ${auth.user.name} ${auth.user.lastName}\nEmail: ${auth.user.email}\n\nProductos:\n\n`
				let totalPrice = 0

				companies[company].forEach(item => {
					totalPrice += item.item.price * item.amount
					text += `Nombre: ${item.item.name}\nDescripción: ${item.item.description}\nPrecio: $ ${item.item.price}\n\nCantidad: ${item.amount}\n\n`
				})

				text += `\n\n\n Precio total: $ ${totalPrice}`

				const emails = (Object.values(store.users) as any[])
					.filter(u => u?.typeData?.company === company)
					.map(u => u.email)

				if (emails && emails.length > 0) sendEmail(emails, { subject: 'Compra', text, from, pass })
			})
		}

		// Register purchase to database.
		registerPurchase()

		shoppingList.removeAll()
		notifications.notify('Orden Enviada')
	}

	function itemClickHandler(item): void {
		setCatalog(p => ({
			...p,
			productActive: true,
			product: item.item,
			amount: item.amount,
			stand: item.stand,
			purchaseId: item.id
		}))
	}

	return (
		<Container isPortrait={isPortrait} active={shoppingList.state.active}>
			{isPortrait && <BackButton onClick={() => shoppingList.toggle()}>
				<SVG image={closeIcon} />
			</BackButton>}
			<Text oneline H1 bold middle center>Carro de compras</Text>
			<Panel>
				<PanelGrid>
					{(Object.entries(items) as any[]).map(([key, value]) =>
						<AddedItemCard
							key={key}
							item={value.item}
							addedItem={auth.user.shoppingList[key]}
							deleteHandler={() => shoppingList.removeItem(key)}
							onClick={() => itemClickHandler(value)}
						/>)}
				</PanelGrid>
			</Panel>
			<Text center H2 middle bold>Subtotal ({totalAmount} producto{totalAmount != 1 && 's'}) ${totalPrice}</Text>
			<Button onClick={() => purchaseHandler()}>Enviar a mi correo</Button>
		</Container>
	)
}

const AddedItemCardContainer = styled.div`
    height: 4rem;
    display: grid;
    grid-template-columns: 1fr 2fr .5fr;
    background-color: ${p => p.theme.light};
	cursor: pointer;
	border-radius: ${p => p.theme.roundCorners};
	overflow: hidden;
`

const TextCardPanel = styled.div`
    display: grid;
    padding: .1rem;
`

const DeleteButton = styled.div`
    cursor: pointer;
    display: flex;
    :hover {
        * {
            background-color: ${p => p.theme.primary};
        }
    }
    * {
        width: 50%;
        height: 50%;
        margin: auto;
    }
`

function AddedItemCard({ item, addedItem, deleteHandler, onClick }: { item: IItem, addedItem: IAddedItem, deleteHandler(): void, onClick(): void }) {
	return (
		<AddedItemCardContainer>
			<Image url={item.image} onClick={() => onClick()} />
			<TextCardPanel onClick={() => onClick()}>
				<Text dark bold H2 middle center>{item.name}</Text>
				<Text dark bold H3 middle center>${item.price}</Text>
				<Text dark bold H3 middle center>Cantidad: {addedItem.amount}</Text>
			</TextCardPanel>
			<DeleteButton onClick={() => deleteHandler()}><SVG image={deleteIcon} dark contain /></DeleteButton>
		</AddedItemCardContainer>
	)
}