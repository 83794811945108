import React, { useState, useEffect } from 'react'
import { getSpreadsheet, getSpreadsheetSheets } from '../utility/spreadsheet'
import detectBrowserLanguage from 'detect-browser-language'

interface Dictionary<T> { [Key: string]: T }

const spreadsheetID = '1Ewq91OVdVCR3CMPRIeezetAVOyMnYMCbC5YRju4E2Bo'

const Language = React.createContext({})

type TLanguages = 'spanish' | 'english'

interface ILanguageState {
	selected: TLanguages,
	options?: string[],
	words?: Dictionary<Dictionary<string>>,
}

export interface ILanguage {
	state: ILanguageState,
	selectLanguage(language: string): void,
	getText(key: string): string,
}

export function LanguageContext(props) {
	const [state, set] = useState<ILanguageState>({
		selected: 'english',
	})

	async function init(): Promise<void> {
		const browserLanguage = detectBrowserLanguage()

		if (browserLanguage.includes('es')) set(p => ({ ...p, selected: 'spanish' }))

		const values: string[][] = await getSpreadsheet(spreadsheetID, 'Languages')

		const options: string[] = values[0].slice(1)
		const words: Dictionary<Dictionary<string>> = {}

		values.forEach(row => {
			const id = row[0]

			let i = 1

			options.forEach(option => {
				if (!words[option]) words[option] = {}

				words[option][id] = row[i++]
			})
		})

		set(p => ({ ...p, options: options, words: words }))
	}

	useEffect(() => { init() }, [])

	const value: ILanguage = {
		state: state,
		selectLanguage(language: TLanguages) {
			set(p => ({ ...p, selected: language }))
		},
		getText(key: string): string {
			try {
				return state.words[state.selected][key] ?? key
			} catch { return key }
		},
	}

	return <Language.Provider value={value}>{props.children}</Language.Provider>
}

export default Language