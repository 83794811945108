import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    height: 100%;
    * {
        height: 100%;
        width: 100%;
    }
`

export default function Embed({ url }: { url: string }) {
    return (
        <Container>
            <iframe
                allow="camera *;microphone *"
                src={url} />
        </Container>
    )
}