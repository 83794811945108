import React from 'react'
import styled from 'styled-components'

import { Text, InputField, Button, FadeInUp } from '../components/UI'

import { usePopup } from '../hooks'

const Container = styled.div`
    display: ${p => p.active ? 'block' : 'none'};
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 5;
`

const Background = styled.div`
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    /* :hover {
        background-color: rgba(0, 0, 0, .7);
    }
    cursor: pointer; */
`

const PanelContainer = styled.div`
    position: absolute;
    width: 20rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const Panel = styled.div`
    border: .1rem solid ${p => p.theme.light};
    display: grid;
    grid-gap: 1rem;
    padding: 1rem;
`

const Line = styled.div`
    background-color: ${p => p.theme.light};
    height: .1rem;
`

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    height: 2rem;
`

export default function Popup(props) {
    const [popup, setPopup] = usePopup()
    return (
        <Container active={popup.active}>
            <Background />
            <PanelContainer>
            <FadeInUp>
            <Panel>
                <Text center H2 bold>{popup.title}</Text>
                <Line />
                {!popup.isBlockingScreen && <InputField 
                    value={popup.inputfield}
                    placeholder={popup.placeholder}
                    onChange={v => setPopup(prev => { return {...prev, inputfield: v }})}
                />}
                {!popup.isBlockingScreen && <Row>
                    <Button onClick={popup.cancelAction} dark>{popup.cancelText}</Button>
                    <Button onClick={popup.confirmAction}>{popup.confirmText}</Button>
                </Row>}
            </Panel>
            </FadeInUp>
            </PanelContainer>
        </Container>
    )
}