import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useParam, usePavillion, useStore, useSearch, useIsPortrait, useAuth, useElementsManager } from '../hooks'
import { getUrl } from '../firebase/storage'
import View from '../components/View'
import FloatingButton from '../components/FloatingButton'

import Stand from '../components/Stand'
import { Image, Text, FadeInUp, IconButton } from '../components/UI'
import PabillionMobile from '../components/PavillionMobile'
import PasswordPanel from '../components/PasswordPanel'
import PresentationImages from '../components/PresentationImages'
import nextIcon from '../graphics/icons/arrow-right.svg'
import prevIcon from '../graphics/icons/arrow-left.svg'

const Container = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: -1;
`

const Skew = styled.div`
    position: absolute;
    height: 75vh;
    width: 69vh;
    top: 57%;
    left: 47%;

    transform: translate(-50%, -50%) skew(60deg, -30deg) scaleY(.66667);

    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
`

const Grid = styled.div`
    height: 100%;
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);

    ${p => p.columns && css`
        grid-template-columns: ${`repeat(${p.columns}, 1fr)`};
    `}
    ${p => p.rows && css`
        grid-template-rows: ${`repeat(${p.rows}, 1fr)`};
    `}
`

const Block = styled.div`
    width: 100%;
    height: 55%;
    transform: translate(20%, 10%) scaleY(calc(1 / .66667)) skew(-60deg, 30deg);
`

const Gradient = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vw;
    z-index: -2;
    background: rgb(211,237,210);
    background: linear-gradient(140deg, rgba(211,237,210,1) 0%, rgba(46,209,218,1) 100%); 
`

const ImageBackground = styled.div`
    position: fixed;
    width: 100vw;
    height: ${p => p.isPortrait ? '40vh' : '100vh'};
    top:0;
`

const Card = styled.div`
    background-color: ${p => p.theme.primary};
    display: grid;
    grid-template-columns: 15rem 10rem;
    grid-template-rows: 2.5rem 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
	border-radius: ${p => p.theme.roundCorners};
`

const CardHolder = styled.div`
    position: fixed;
    box-sizing: border-box;
    right: 1.5rem;
    bottom: 1.5rem;
    z-index: 4;
    pointer-events: none;

    *:nth-child(2) {
        height: 100%;
    }
`

const LogoHolder = styled.div`
    padding: 1rem;
    box-sizing: border-box;
    grid-column: 2/3;
    grid-row: 2/3;
    background-color: ${p => p.theme.light};
    border-left: solid .1rem ${p => p.theme.primary};
`

const StandNameContainer = styled.div`
    grid-column: 1/3;
    grid-row: 1/2;
    padding: 0 1rem;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const CustomButton = styled.div`
    position: fixed;

    width: 2.5rem;
    height: 2.52rem;

    transform: translateY(-50%);
    border-radius: 50%;
    overflow: hidden;

    * { padding: .2rem; }

    transition: ${p => p.theme.transitionDuration};
    :hover { transform: translateY(-50%) scale(1.3); }

    z-index: 1;
`

export default function Pavillion() {
	const pavillionId = useParam('id')
	const pavillion = usePavillion(pavillionId)
	const [store, dispatch] = useStore()
	const [hoveredStand, setHoveredStand] = useState()
	const [hoveredStandBackground, setHoveredStandBackground] = useState()
	const [blocks, setBlocks] = useState()
	const [search, setSearch] = useSearch()
	const [components, setComponents] = useState([])
	const isPortrait = useIsPortrait()
	const auth = useAuth()
	const elementsManager = useElementsManager()

	// Stands.
	const [pageList, setPageList] = useState({
		filtered: [],
		page: [],
		currentPage: 0,
		totalPages: 0,
	})

	const [init, setInit] = useState(true)

	const getCompanyLogo = stand => {
		if (store.companies &&
			stand.company in store.companies) return store.companies[stand.company].logo
	}

	const maxPerPage = (pavillion.gridColumns ? pavillion.gridColumns : 5) * (pavillion.gridRows ? pavillion.gridRows : 5)

	const getFiltered = () => {
		if (!pavillion.stands) return

		const filter = s => {
			if (search.value === '') return true
			const v = search.value.toLowerCase()

			if (s.name && s.name.toLowerCase().includes(v)) return true
			if (s.description && s.description.toLowerCase().includes(v)) return true
			if (s.tags && s.tags.toLowerCase().includes(v)) return true

			return false
		}

		return Object.keys(pavillion.stands)
			.map(id => store.stands[id])
			.filter(s => s)
			.filter(filter)
			.sort((a, b) => {
				const av = a.order ? parseFloat(a.order) : Number.MAX_SAFE_INTEGER
				const bv = b.order ? parseFloat(b.order) : Number.MAX_SAFE_INTEGER
				return av > bv ? 1 : -1
			})
	}

	useEffect(() => {
		if (init) {
			setSearch(prev => { return { ...prev, value: '' } })
			setInit(false)
		}
	}, [init])

	// Set hovered stand background.
	useEffect(() => {
		if (hoveredStand) {
			if (hoveredStand.background) getUrl(hoveredStand.background).then(setHoveredStandBackground)
			if (hoveredStand.backgrounds && hoveredStand.backgrounds.images) getUrl(Object.values(hoveredStand.backgrounds.images)[0]).then(setHoveredStandBackground)
		}
	}, [hoveredStand])

	// Set filtered.
	useEffect(() => {
		const filtered = getFiltered()
		if (!filtered) return
		setPageList(prev => {
			return {
				...prev,
				filtered: filtered,
				currentPage: 0,
				totalPages: Math.ceil(filtered.length / maxPerPage),
			}
		})

	}, [search.value, pavillion.stands])

	// Set current page.
	useEffect(() => {
		if (!pageList.filtered) return

		let from = pageList.currentPage * maxPerPage
		let to = from + maxPerPage
		if (to > pageList.filtered.length) to = pageList.filtered.length

		setPageList(prev => {
			return {
				...prev,
				page: pageList.filtered.slice(from, to)
			}
		})
	}, [pageList.currentPage || pageList.filtered])

	// Set blocks.
	useEffect(() => {
		setBlocks(pageList.page.map(s => <Block key={s.id}>
			<Stand
				pavillion={pavillion}
				stand={s}
				mouseEnter={setHoveredStand}
				mouseLeave={() => setHoveredStand()}
			/>
		</Block>))
	}, [pageList.page])

	useEffect(() => {
		const c = []
		if (pavillion.backgrounds) c.push(<PresentationImages key='backgrounds' backgrounds={pavillion.backgrounds} />)
		if (pavillion.background) c.push(<Image key='bg' url={pavillion.background} />)
		if (!isPortrait) {
			if (pavillion.views && elementsManager.state.panels) c.push(Object.values(pavillion.views).map(view => <View key={view.id} view={view} />))
			if (pavillion.buttons && elementsManager.state.buttons) c.push(Object.values(pavillion.buttons).map(btn => <FloatingButton key={btn.id} button={btn} />))
		}
		setComponents(c)
	}, [store, pavillion.id, elementsManager.state])

	const switchPage = v => {
		let nextPage = pageList.currentPage += v
		if (nextPage >= pageList.totalPages) nextPage = 0
		if (nextPage < 0) nextPage = pageList.totalPages - 1

		setPageList(prev => { return { ...prev, currentPage: nextPage } })
	}

	const nextHandler = () => switchPage(1)
	const prevHandler = () => switchPage(-1)

	function selectPage(i) { setPageList(prev => { return { ...prev, currentPage: i } }) }

	if (!isPortrait) return (
		<Container>
			{(pavillion.password && !auth?.user?.universalAccess && (!pavillion.whitelist || !(auth?.user?.id in pavillion.whitelist))) && <PasswordPanel password={pavillion.password} title={pavillion.passwordTitle} />}
			<PageNavegation selectPage={i => selectPage(i)} currentPage={pageList.currentPage} totalPages={pageList.totalPages} />
			<Gradient />
			{hoveredStand &&
				<CardHolder>
					<FadeInUp>
						<Card>
							<StandNameContainer><Text H2 bold middle oneline>{hoveredStand.name}</Text>
							</StandNameContainer>
							<Text style={{ backgroundColor: 'white', padding: '1rem', gridColumn: '1/2', gridRow: '2/3' }} H3 dark>{hoveredStand.description}</Text>
							<LogoHolder><Image contain url={getCompanyLogo(hoveredStand)} /></LogoHolder>
						</Card>
					</FadeInUp>
				</CardHolder>
			}

			{pageList.totalPages > 1 &&
				<CustomButton style={{ top: '50vh', right: '4rem' }}>
					<IconButton light onClick={nextHandler} image={nextIcon} />
				</CustomButton>}

			{pageList.totalPages > 1 &&
				<CustomButton style={{ top: '50vh', left: '4rem' }}>
					<IconButton light onClick={prevHandler} image={prevIcon} />
				</CustomButton>}

			<ImageBackground>
				{components}
			</ImageBackground>

			<Skew>
				<Grid
					columns={pavillion.gridColumns}
					rows={pavillion.gridRows}
				>
					{blocks}
				</Grid>
			</Skew>
		</Container>
	)

	else return (
		<ImageBackground cover isPortrait={true}>
			{components}
			<PabillionMobile
				pageList={pageList}
				pavillion={pavillion}
				nextHandler={nextHandler}
				prevHandler={prevHandler}
				selectPage={i => selectPage(i)}
			/>
		</ImageBackground>
	)
}

const PageNavegationContainer = styled.div`
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    height: 2rem;
    z-index: 2;
    padding: .5rem;
    border-radius: 1rem;

    opacity: ${p => p.show ? 1 : 0};

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 2rem;
    grid-gap: 1rem;
    background-color: ${p => p.theme.primary};
`

const PageNavegationItem = styled.div`
    border-radius: 50%;
    border: .3rem solid ${p => p.theme.light};
    transition: .3s;
    :hover { background-color: ${p => p.theme.light}; transform: translateY(-.2rem)}
    background-color: ${p => p.selected ? p.theme.light : 'none'};
    cursor: pointer;
`

function PageNavegation(props) {
	return (
		<PageNavegationContainer show={props.totalPages > 0}>
			{Array.from(Array(props.totalPages)).map((v, i) => <PageNavegationItem onClick={() => props.selectPage(i)} selected={props.currentPage === i} />)}
		</PageNavegationContainer>
	)
}