import firebase from './'

const database = firebase.database()

export const getContent = path => new Promise(async (resolve, reject) => {
    try {
        const snapshot = await database.ref(path).once('value')
        resolve(snapshot.val())
    } catch(error) { reject(error) }
})

export const subscribeToContent = (path, callback) => {
	const ref = database.ref(path)

    ref.on('value', (snapshot) => {
        if(snapshot) callback(snapshot.val())
    })

	function cancelSubscription() { ref.off('value') }

	return cancelSubscription
}

export const setContent = (path, payload) => new Promise(async (resolve, reject) => {
    try {
        if (path) { 
            const snapshot = await database.ref(path).set(payload)
            resolve(snapshot)
        }
        else reject('null data')
    } catch(error) { reject(error) }
})

export const onDisconnect = (path, value) => {
    if (path && value != null) database.ref(path).onDisconnect().set(value)
}