import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Image } from './UI'
import { useIsPortrait } from '../hooks'

const Container = styled.div`
    display: relative;
    height: 100%;
`

const Item = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: ${p => p.selected ? 1 : 0};
    z-index: 0;
`

export default function PresentationImages(props) {
    const isPortrait = useIsPortrait()
    const [state, setState] = useState({
        images: [],
        selected: 0,
    })

    const awake = () => {
        if (!props.backgrounds) return
        if (props.backgrounds.images) setState({ ...state, images: Object.values(props.backgrounds.images) })

        const time = props.backgrounds.time ? props.backgrounds.time * 1000 : 10000
        const interval = setInterval(() => {
            setState(prev => { return { ...prev, selected: (prev.selected + 1) >= prev.images.length ? 0 : prev.selected + 1 } })
        }, time)

        return () => { clearInterval(interval) }
    }

    useEffect(awake, [props.backgrounds])

    return (
        <Container>
            {state.images.map((image, i) => <Item key={i} selected={i === state.selected}><Image fit top={isPortrait} contain={isPortrait} url={image} /></Item>)}
        </Container>
    )
}