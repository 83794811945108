import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Reveal from 'react-reveal/Reveal';
import { useNotifications, useStore, useAuth } from '../hooks'
import { subscribeToContent } from '../firebase/database'
import useState from 'react-usestateref'
import useSound from 'use-sound'
import sound from '../sounds/notification.wav'

import closeIcon from '../graphics/icons/close.svg'
import { SVG } from './UI'
import Linkify from 'react-linkify'

const SClose = styled.div`
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: .6rem;
    right: .6rem;
    pointer-events: none;
`

const Holder = styled.div`
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: middle;
    flex-direction: column;
    padding: 1em;
    gap: 1em;
    transition: 1s;
    z-index: 10;
`

const hideAnim = keyframes`
    0% {
        opacity: 1;
        transform-origin: 0 0;
    }
    100% {
        opacity: 0;
        transform-origin: 0 -50%;
    }
`

const TagContainer = styled.div`
    margin: auto;
    max-width: 40rem;
`

const Tag = styled.div`
    position: relative;
    background-color: ${p => p.theme.dark};
    text-align: center;
    /* white-space: nowrap; */
    padding: .5em 3em;
    color: ${p => p.theme.light};
    font-weight: bold;

    pointer-events: all;
    cursor: pointer;

    border: .08rem solid ${p => p.theme.primary};

    animation: ${p => p.state ? '' : hideAnim} 1s ease-in-out forwards;
`

const componentDecorator = (href, text, key) => (
	<a href={href} key={key} target="_blank">
		{text}
	</a>
)

export default function Notifications() {
	const [ready, setReady, readyRef] = useState(false)
	const [playSound, setPlaySound] = useState(false)
	const [loadingNotifications, setLoadingNotifications] = useState(0)
	const notifications = useNotifications()
	const auth = useAuth()
	const [playMessageSound] = useSound(sound)
	const [store, dispatch] = useStore()

	function calculateAvarageReadingTime(text) {
		if (!text) return 0

		try {
			const words = text.split(" ").length

			// 200 words per minute being the avarage reading speed.
			const milliseconds = (words / 200 * 60 * 1000) + 5000

			return milliseconds

		} catch { return 5000 }
	}

	useEffect(() => {
		// setTimeout(() => {
		//     setReady(true)
		// }, 2000)

		// subscribeToContent('notification', v => {
		//     if (readyRef.current && v) {
		//         notifications.notify(v?.message, calculateAvarageReadingTime(v?.message))
		//         setPlaySound(true)
		//     }
		// })
	}, [])

	useEffect(() => {
		if (!store.notifications) return

		if (loadingNotifications < 2) {
			setLoadingNotifications(loadingNotifications + 1)
			return
		}

		const allNotifcation = (Object.values(store.notifications)).filter(n => { return !(n.id in auth.user.notifications) }).reverse()

		if (allNotifcation.length > 0) {
			const newNotification = allNotifcation[0]
			notifications.notify(newNotification.text, calculateAvarageReadingTime(newNotification.text))
			setPlaySound(true)
		}

	}, [store.notifications])

	useEffect(() => {
		if (playSound) {
			playMessageSound()
			setPlaySound(false)
		}
	}, [playSound])

	return (
		<Holder>
			{Object.entries(notifications.notifications)
				.reverse()
				.map(([key, value]) => (<Reveal effect='fadeInUp' duration={500}>
					<TagContainer >
						<Tag onClick={() => notifications.closeNotification(key)} state={value.state} style={{ backgroundColor: '#ffffffe6', color: 'black', borderRadius: '5px', top: '50px', border: 'none' }}>
							<SClose>
								<SVG contain image={closeIcon} dark />
							</SClose>
							<Linkify componentDecorator={componentDecorator}>{value.text}</Linkify>
						</Tag>
					</TagContainer>
				</Reveal>))}
		</Holder>
	)
}