import React, { useState, useEffect } from 'react'
import { useAuth, useStore } from '../hooks'
import { key } from 'firebase-key'

import { getSpreadsheet, getSpreadsheetSheets } from '../utility/spreadsheet'

interface Dictionary<T> { [Key: string]: T }

const ShoppingList = React.createContext({})

interface IShoppingListState {
	active: boolean,
}

export interface IShoppingList {
	state: IShoppingListState,
	addItem(standId: string, itemId: string, amount: number): void, // Adds new item to shopping list.
	editItem(purchaseId: string, amount: number): void, // Edit purchase values.
	removeItem(id: string): void, // Removes item from shopping list.
	removeAll(): void,
	toggle(): void,
}

export interface IAddedItem {
	id: string,
	standId: string,
	itemId: string,
	amount: number
}

export function ShoppingListContext(props) {
	const auth = useAuth()
	const [store, dispatch] = useStore()
	const [state, set] = useState<IShoppingListState>({
		active: false,
	})

	// Save user to database.
	function saveUser(user) {
		auth.editUser(user)
		dispatch({ type: 'set-user', payload: user })
	}

	useEffect(() => {
		// customImportFromSpreadSheet(store, dispatch, '1JzzlI_NDEPGrEbq2W5NNxm5S2R6V7-LuLug4VSGv8xM')
	}, [store.stands])

	const value: IShoppingList = {
		state: state,
		addItem(standId, itemId, amount) {
			if (!auth.user) return
			if (!auth.user.shoppingList) auth.user.shoppingList = {}

			const newItem: IAddedItem = {
				id: key(),
				standId: standId,
				itemId: itemId,
				amount: amount
			}

			auth.user.shoppingList[newItem.id] = newItem

			saveUser(auth.user)
		},
		editItem(purchaseId, amount) {
			if (!auth?.user?.shoppingList[purchaseId]) return

			auth.user.shoppingList[purchaseId].amount = amount

			saveUser(auth.user)
		},
		removeItem(id) {
			if (!auth?.user?.shoppingList) return

			delete auth.user.shoppingList[id]

			saveUser(auth.user)
		},
		removeAll() {
			if (!auth?.user?.shoppingList) return

			auth.user.shoppingList = {}

			saveUser(auth.user)
		},
		toggle() {
			set(prev => { return { ...prev, active: !prev.active } })
		},
	}

	return <ShoppingList.Provider value={value}>{props.children}</ShoppingList.Provider>
}


let customImportState = false
async function customImportFromSpreadSheet(store, dispatch, spreadsheetId): Promise<void> {
	if (customImportState || !store?.stands) return
	customImportState = true

	const sheets: string[] = await getSpreadsheetSheets(spreadsheetId)

	async function addStandProducts(stand: any, sheet: string): Promise<void> {
		const spreadsheet = (await getSpreadsheet(spreadsheetId, sheet)) as string[][]
		const products: Dictionary<any> = {}

		let firstRow: string[] = undefined

		for (const row of spreadsheet) {
			// Set first row and skip.
			if (!firstRow) {
				firstRow = row

				for (let i = 0; i < firstRow.length; i++) {
					try {
						if (firstRow[i]?.toLocaleLowerCase() === 'nombre producto') firstRow[i] = 'name'
						if (firstRow[i]?.toLocaleLowerCase() === 'descripción producto') firstRow[i] = 'description'
						if (firstRow[i]?.toLocaleLowerCase() === 'nombre img') firstRow[i] = 'images'
						if (firstRow[i]?.toLocaleLowerCase().includes('precio')) firstRow[i] = 'price'

						firstRow[i] = firstRow[i] ?? 'bad'

					} catch (error) { console.log('Error A', error) }
				}

				continue
			}

			const newProduct: any = { id: key() }

			// Populate new product values.
			for (let i = 0; i < firstRow.length; i++) newProduct[firstRow[i]] = row[i] ?? ''

			// Ignore empty ones.
			if (newProduct.name === '') continue

			// Create images dictionary.
			if (newProduct.images) {
				const names: string[] = (newProduct.images as string).split(',')
				if (names) {
					newProduct.images = names.reduce((object, item) => {
						item = item.replace(/(\r\n|\n|\r)/gm, '')
						let withouExtension = item.replaceAll('.', '')
						if (withouExtension != '') return { ...object, [withouExtension]: `products/${item}` }
					}, {})
					newProduct.image = `${Object.values(newProduct.images)[0]}`
				}
			}

			// Save new product.
			products[newProduct.id] = newProduct
		}


		// Set stand.
		stand.catalog = products
		dispatch({ type: 'set-stand', payload: { ...stand } })
	}

	for (const sheet of sheets) {
		try {
			const standId = sheet.substring(sheet.indexOf('-')).replace(/ /g, "")
			if (store?.stands && store.stands[standId]) {
				console.log(standId)
				await addStandProducts(store.stands[standId], sheet)
			}
		} catch { }

	}

	console.log('Product import done.')
}

export default ShoppingList