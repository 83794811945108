import React, { useState } from 'react'

const CompanyPoster = React.createContext({})

interface IState {
	active: boolean,
	company?: any,
}

export function CompanyPosterContext(props) {
	const [state, set] = useState<IState>({
		active: false,
	})

	return (
		<CompanyPoster.Provider value={[state, set]}>
			{props.children}
		</CompanyPoster.Provider>
	)
}

export default CompanyPoster