import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    height: 100%;
    * {
        height: 100%;
        width: 100%;
    }
`

export default function Vimeo({id }: {id: string}) {
    return (
        <Container>
            <iframe 
                src={`https://player.vimeo.com/video/${id}?autoplay=1&loop=1&title=0&byline=0&portrait=0`}
                //@ts-ignore
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen />

            <script src="https://player.vimeo.com/api/player.js"></script>
        </Container>
    )
}