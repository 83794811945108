import React, { useState } from 'react'
import styled from 'styled-components'

import { TUsernameView } from './'

import { useStore, useLanguage, useParam } from '../../hooks'
import { Button, InputField, Text } from '../UI'
import icons from '../../graphics/icons'
import { Feedback } from './'

const SUsernameView = styled.div`
    height: min-content;
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

const SRow = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
    grid-template-columns: 1fr 1fr;
`

const SColumn = styled.div`
    height: min-content;
    display: grid;
`

const SOtherLogins = styled.div`
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
	height: 3rem;
	grid-template-columns: 3rem 3rem;
	margin: auto;
	margin-top: 1rem;
`

const SColorIcon = styled.div<{ image: string }>`
	background: url(${p => p.image});
	background-size: contain;
	cursor: pointer;
	transition: .3s;
	:hover {
		transform: scale(1.1);
	}
`

interface IProps {
	usernameView: TUsernameView,
	setUsernameView(usernameView: TUsernameView): void,
	continueHandler(email: string, password: string): void,
	specialHandler(type: 'google' | 'facebook'): Promise<void>,
	resetPasswordHandler(): void,
	feedback: string,
}

interface IState {
	email: string,
	password: string,
}

export default function UsernameView(props: IProps) {
	const language = useLanguage() as any
	const [state, set] = useState<IState>({
		email: '',
		password: ''
	})
	const [store] = useStore()
	const adminRegister = useParam('adminRegister')

	function continueHandler(): void {
		props.continueHandler(state.email?.trim(), state.password)
	}

	function textClickHandler(): void {
		if (props.usernameView === 'login') props.resetPasswordHandler()
		else window.open(store?.configurations?.otherLinks?.termsAndConfitions, '_blank').focus()
	}

	const showWelcomeText = store?.configurations?.customTexts?.welcomeText
	const showFooterText = store?.configurations?.customTexts?.loginFoot

	const showGoogleLogin = store?.configurations?.allowGoogleSignin
	const showFacebookLogin = store?.configurations?.allowFacebookSignin

	// Can overpass this by adding adminRegister=enabled param, hack
	function canRegister(): boolean {
		if (!store?.configurations?.allowRegister) {
			return adminRegister === 'enabled'
		}

		return true
	}

	return (
		<SUsernameView>
			<SRow>
				<Button disabled={!canRegister()} light={props.usernameView != 'register'} onClick={() => props.setUsernameView('register')}>{language.getText('Registrarse')}</Button>
				<Button light={props.usernameView != 'login'} onClick={() => props.setUsernameView('login')}>{language.getText('Ingresar')}</Button>
			</SRow>
			{showWelcomeText && <Text medium subtext>{showWelcomeText}</Text>}
			<InputField
				title={language.getText('Email')}
				value={state.email}
				onChange={v => set(p => ({ ...p, email: v }))}
				onKeyDown={e => { if (e.keyCode === 13) continueHandler() }}
			/>
			<InputField
				title={language.getText('Contraseña')}
				value={state.password}
				onChange={v => set(p => ({ ...p, password: v }))}
				onKeyDown={e => { if (e.keyCode === 13) continueHandler() }}
				type='password'
			/>

			{props.feedback && (<Feedback feedback={props.feedback} />)}

			{(props.usernameView === 'register' ? store?.configurations?.otherLinks?.termsAndConfitions : true) && <SColumn>
				<Text medium subtext>{props.usernameView === 'register' ? language.getText('Al registrarte aceptarás nuestros') : language.getText('¿Olvidaste tu contraseña?')}</Text>
				<Text primary subtext onClick={() => textClickHandler()}>{props.usernameView === 'register' ? language.getText('términos y condiciones') : language.getText('Reestablecer ahora')}</Text>
			</SColumn>}
			<Button onClick={() => props.continueHandler(state.email, state.password)}>{language.getText('Continuar')}</Button>
			{showFooterText && <Text medium subtext>{showFooterText}</Text>}
			{
				(showGoogleLogin || showFacebookLogin) && <SColumn>
					<Text medium bold center H5>{language.getText('o ingresa con')}</Text>
					<SOtherLogins>
						{showGoogleLogin && <SColorIcon image={icons.googleColor} onClick={() => props.specialHandler('google')} />}
						{showFooterText && <SColorIcon image={icons.facebookColor} onClick={() => props.specialHandler('facebook')} />}
					</SOtherLogins>
				</SColumn>
			}
		</SUsernameView >
	)
}
