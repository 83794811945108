import { IVisitState } from '../context/AnalyticsContext'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import { getContent } from '../firebase/database'

const JSZip = require('jszip')

class CSV {
	rows: string[][]
	currentRow: string[]

	constructor() {
		this.rows = []
		this.currentRow = []
	}

	// Add item to row, remove \,
	addItems(items: string[]): void {
		for (let item of items) {
			if (!item) item = ''
			this.currentRow.push(item)
		}
	}

	endRow(): void {
		this.rows.push(this.currentRow)
		this.currentRow = []
	}

	getFile(name: string): { data: any, name: string } {
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileExtension = '.xlsx';
		const ws = XLSX.utils.json_to_sheet(this.rows, { skipHeader: true })
		const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
		const data = new Blob([excelBuffer], { type: fileType })

		return { data: data, name: (name + fileExtension) }
	}

	downloadFile(name) {
		const file = this.getFile(name)
		FileSaver.saveAs(file.data, file.name)
	}
}

export async function downloadAll(store: any, areas: any, visitStates: any, zipName: string): Promise<void> {
	const zip = new JSZip()

	if (visitStates) {
		for (const visitState of (Object.values(visitStates) as any[])) {
			if (visitState) {
				const area = areas[visitState.id]
				if (area) {
					const file = await downloadAnalytics(store, area, visitState, false)
					if (file) zip.file(file.name, file.data)
				}
			}
		}
	}

	zip.generateAsync({ type: 'blob' }).then(function (content) {
		FileSaver.saveAs(content, `${zipName || 'Stats'}.zip`)
	})
}

function getTypeName(user: any): string {
	if (user.type === 'admin') return 'Admin'
	if (user.type === 'organizer') return 'Organizador'
	if (user.type === 'expositor') return 'Expositor'
	return 'Visitante'
}

export async function downloadUsersCSV(store, extraInfo, users): Promise<void> {
	if (!users) return
	const csv = new CSV()
	csv.addItems(['Nombre', 'Apellido', 'Email', 'Fecha de Registro', 'Rango'])

	// Add extra info names to first row.
	if (extraInfo) (Object.values(extraInfo) as any[]).forEach(info => csv.addItems([info.name]))

	// End first row.
	csv.endRow()

	try {
		(Object.values(users) as any[]).forEach(user => {
			if (user) {
				csv.addItems([user.name, user.lastName, user.email, user.creationTime ?? '', getTypeName(user)])

				if (extraInfo && user.extraInfo) {
					(Object.keys(extraInfo) as string[]).forEach(infoId => {
						let value = user.extraInfo[infoId]
						if (user[infoId]) value = user[infoId]
						if (extraInfo[infoId]?.type === 'boolean') {
							value = value ? 'si' : 'no'
						}
						if (extraInfo[infoId]?.type === 'dropdown') {
							let text = ''
							let first = true
							if (!value) text = 'sin marcar'
							else {
								try {
									(Object.values(value) as string[]).forEach(optionId => {
										if (first) first = false
										else text += `, `
										text += `${extraInfo[infoId]?.options[optionId]?.name}`
									})
								} catch { }
							}
							value = text
						}
						csv.addItems([value])
					})
				}

				csv.endRow()
			}
		})
	} catch { }

	// console.log(csv)
	csv.downloadFile('users')
}

export function downloadPurchaseHistoryCsv(store): Promise<boolean> {
	return new Promise(async (resolve, reject) => {
		const purchaseHistory = await getContent('purchaseHistory')

		const userExtraInfo = store?.configurations?.userExtraInfo ?? {}

		if (!purchaseHistory) {
			resolve(false)
			return
		}

		const csv = new CSV()
		csv.addItems(['Fecha', 'Nombre', 'Apellido', 'Email']);
		(Object.values(userExtraInfo) as any[]).forEach(ei => csv.addItems([ei.name ?? '']))
		csv.addItems(['Producto', 'Precio', 'Código de barra', 'Plazo de pago', '% Descuento', 'Cantidad'])
		csv.endRow()

		for (const purchase of (Object.values(purchaseHistory) as any[])) {
			try {
				// Get variables.
				const user = store?.users[purchase.user]
				const stand = store?.stands[purchase.stand]
				const product = stand.catalog[purchase.product]

				if (!user.extraInfo) user.extraInfo = {}

				csv.addItems([
					purchase.timestamp,
					user?.name,
					user?.lastName,
					user?.email,
				]);

				(Object.values(userExtraInfo) as any[]).forEach(ei => csv.addItems([user?.extraInfo[ei.id] ?? '']))

				csv.addItems([
					product.name ?? '',
					product.price ?? '',
					product['Código de Barra'] ?? '',
					product['Plazo de pago'] ?? '',
					product['% Descuento'] ?? '',
					purchase.amount ?? ''
				]);

				csv.endRow()
			} catch (error) { console.log(error) }
		}

		csv.downloadFile('historial_de_compra')

		resolve(true)
	})
}

export function downloadCompanyPurchaseHistory(store, company, standID?: string): Promise<boolean> {
	return new Promise<boolean>(async (resolve, reject) => {
		if (!company) return
		const purchaseHistory = await getContent('purchaseHistory')
		const userExtraInfo = store?.configurations?.userExtraInfo ?? {}

		if (!purchaseHistory) {
			resolve(false)
			return
		}

		const csv = new CSV()
		csv.addItems(['Empresa', 'Stand', 'Fecha', 'Nombre', 'Apellido', 'Email']);
		(Object.values(userExtraInfo) as any[]).forEach(ei => csv.addItems([ei.name ?? '']))
		csv.addItems(['Producto', 'Descripción', 'Precio', 'Código de barra', 'Plazo de pago', '% Descuento', 'Cantidad'])
		csv.endRow()

		for (const purchase of (Object.values(purchaseHistory) as any[])) {
			try {
				// Get variables.
				const user = store?.users[purchase.user]
				const stand = store?.stands[purchase.stand]
				const product = stand.catalog[purchase.product]

				// Only add items that belong to the company.
				if (stand.company != company.id) continue
				if (standID && standID != stand.id) continue
				if (!product) continue

				if (!user.extraInfo) user.extraInfo = {}

				csv.addItems([
					company?.name ?? '',
					stand?.name ?? '',
					purchase.timestamp,
					user?.name,
					user?.lastName,
					user?.email,
				]);

				(Object.values(userExtraInfo) as any[]).forEach(ei => csv.addItems([user?.extraInfo[ei.id] ?? '']))

				csv.addItems([
					product.name ?? '',
					product.description ?? '',
					product.price ?? '',
					product['Código de Barra'] ?? '',
					product['Plazo de pago'] ?? '',
					product['% Descuento'] ?? '',
					purchase.amount ?? ''
				]);

				csv.endRow()
			} catch (error) { console.log(error) }
		}

		// console.log(csv)
		csv.downloadFile(`historial_de_compra_${company.name}`)

		resolve(true)
	})
}

export async function downloadAnalytics(store: any, area: any, visitState: IVisitState, download: Boolean = true): Promise<{ data: any, name: string }> {
	if (!area.name) return

	if (area.pavillion && store.pavillions && store.pavillions[area.pavillion])
		area.pavillionName = store.pavillions[area.pavillion].name

	const csv = new CSV()
	if (area.pavillionName) csv.addItems(['Nombre', 'Pabellón', 'Visitas totales'])
	else csv.addItems(['Nombre', 'Visitas totales'])

	csv.endRow()

	if (area.pavillionName) csv.addItems([area.name, area.pavillionName, visitState?.totalVisits?.toString() || ''])
	else csv.addItems([area.name, visitState.totalVisits.toString()])


	csv.endRow()
	csv.endRow()

	const hasButtons = visitState.buttons && area.buttons

	if (hasButtons) {
		csv.addItems(['Botones:'])
		csv.endRow()
		csv.endRow()

		Object.values(visitState.buttons).forEach(buttonState => {
			const button = area.buttons[buttonState.id]
			if (button && button.name) {
				csv.addItems([button.name, buttonState?.totalVisits?.toString() || '0'])
				csv.endRow()
			}
		})
	}

	csv.endRow()

	if (visitState.users) {
		csv.addItems(['USUARIOS:'])
		csv.endRow()

		csv.addItems(['Nombre', 'Apellido', 'Email', 'Rango'])
		if (store?.configurations.userExtraInfo) (Object.values(store.configurations.userExtraInfo) as any[]).forEach(info => csv.addItems([info.name]))
		if (hasButtons) Object.values(visitState.buttons).forEach(buttonState => {
			const button = area.buttons[buttonState.id]
			if (button && button.name) csv.addItems([button.name])
		})
		csv.endRow()

		Object.keys(visitState.users).forEach(id => {
			const user = store.users[id]
			if (user) {
				csv.addItems([user.name, user.lastName, user.email, getTypeName(user)])
				if (store?.configurations.userExtraInfo && user.extraInfo) (Object.keys(store.configurations.userExtraInfo) as string[]).forEach(info => csv.addItems([user.extraInfo[info]]))

				if (hasButtons) {
					(Object.values(visitState.buttons) as any[]).forEach(b => {
						if (!area.buttons[b.id] || !area.buttons[b.id].name) { }
						else if (b && user.id in b.users) csv.addItems(['X'])
						else csv.addItems([' '])
					})
				}

				csv.endRow()
			}
		})
	}

	const fileName = area.pavillionName ? `${area.name}_${area.pavillionName}` : area.name
	if (download) csv.downloadFile(fileName)

	return csv.getFile(fileName)
}