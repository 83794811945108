import React, { useState } from 'react'
import styled from 'styled-components'
import { useStore, useNotifications, useAuth } from '../../hooks'
import { key } from 'firebase-key'
import { uploadImage, uploadDownloadableFile } from '../../firebase/storage'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import List from 'react-virtualized/dist/commonjs/List'
import { downloadCompanyPurchaseHistory } from '../../utility/analyticsCSV'

import { Text, Button, Background, Image, FadeInUp, UploadFile, InputField, IconButton } from '../UI'
import closeIcon from '../../graphics/icons/arrow-left.svg'

const LIST = 0
const SELECTED = 1

const Container = styled.div`
    height: 100%;
`

export default function Companies(props) {
	const [state, setState] = useState({
		menu: LIST, // UI state.
		selected: null, // Selected company
	})

	// Get UI content.
	const getContent = () => {
		if (state.menu === LIST) return <ListCompany
			selectCompany={c => setState({ ...state, menu: SELECTED, selected: c })}
		/>

		if (state.menu === SELECTED) return <EditCompany
			company={state.selected}
			backHandler={() => {
				setState({ ...state, menu: LIST, selected: null })
			}}
		/>
	}

	return (
		<Container>
			{getContent()}
		</Container>
	)
}

const EditCompanyContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
    padding-bottom: 2rem;
`

const LogoHolder = styled.div`
    width: 20rem;
    height: 12rem;
    border: .1rem solid ${p => p.theme.light};
`

const ToggleRow = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2rem auto;
    height: 2rem;
    cursor: pointer;
    transition: .3s;
    :hover {
        transform: translateY(.2rem);
    }
`

const Checkbox = styled.div`
    background-color: ${p => p.selected ? p.theme.primary : p.theme.light};
    border: .2rem solid ${p => p.theme.light};
    box-sizing: border-box;
    transition: .3s;
	cursor: pointer;
`

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`

function EditCompany(props) {
	const [store, dispatch] = useStore()
	const [state, setState] = useState({})
	const [company, setCompany] = useState(props.company) // Selected company to edit.
	const notifications = useNotifications()
	const auth = useAuth()
	const isExpositor = auth.isExpositor()

	function canEdit() {
		if (auth?.user?.type === 'admin' || auth?.user?.type === 'organizer') return true
		return false
	}

	// Upload company's logo.
	const uploadHandler = async () => {
		const logo = state.logoImage
		setState({ ...state, logoImage: null, loading: true })
		try {
			const id = await uploadImage(logo, company.logo)
			saveCompany({ ...company, logo: id })
			setState({ ...state, loading: false })
		} catch (error) {
			setState({ ...state, loading: false })
			notifications.notify(error)
		}
	}

	async function uploadFullImage() {
		const image = state.fullImage
		setState({ ...state, fullImage: null, loading: true })
		try {
			const id = await uploadImage(image, company.fullImage)
			saveCompany({ ...company, fullImage: id })
			setState({ ...state, loading: false })
		} catch (error) {
			setState({ ...state, loading: false })
			notifications.notify(error)
		}
	}

	async function uploadDownloadFile() {
		const file = state.downloadFile
		setState(p => ({ ...p, downloadFile: undefined, loading: true }))
		try {
			const url = await uploadDownloadableFile(file)
			saveCompany({ ...company, file: url })
			setState(p => ({ ...p, loading: false }))
		} catch (error) {
			setState(p => ({ ...p, loading: false }))
			notifications.notify(error)
		}
	}

	// Save company to store.
	const saveCompany = c => {
		setCompany(c)
		dispatch({ type: 'set-company', payload: c })
	}

	// Delete company from store.
	const deleteCompany = () => {
		dispatch({ type: 'delete-company', payload: company })
		props.backHandler()
	}

	async function downloadPurchaseHistory() {
		if (state.downloadingHistory) return
		setState(p => ({ ...p, downloadingHistory: true }))
		await downloadCompanyPurchaseHistory(store, company)
		setState(p => ({ ...p, downloadingHistory: false }))
	}

	return (
		<EditCompanyContainer>
			<CloseButton><IconButton onClick={props.backHandler} image={closeIcon} /></CloseButton>
			<Text bold H1>{company.name}</Text>

			<Button onClick={() => downloadPurchaseHistory()} style={{ height: '2rem', width: '15rem' }}>{state.downloadingHistory ? 'Descargando...' : 'Historial de compra'}</Button>

			<Text bold H2>Logo</Text>
			{company.logo && <LogoHolder><Image contain url={company.logo} /></LogoHolder>}
			<Text bold H2>Cambiar Logo</Text>
			{state.loading ? <Text bold H2>Subiendo...</Text> : <UploadFile style={{ height: '2rem', width: '15rem' }} fileHanlder={file => setState({ ...state, logoImage: file })}>Subir nueva imagen</UploadFile>}
			{state.logoImage && <Button onClick={uploadHandler} style={{ height: '2rem', width: '15rem' }}>Subir</Button>}

			<Text bold H2>Imagen Grande</Text>
			{company.logo && <LogoHolder><Image contain url={company.fullImage} /></LogoHolder>}
			<Text bold H2>Cambiar Imagen Grande</Text>
			{state.loading ? <Text bold H2>Subiendo...</Text> : <UploadFile style={{ height: '2rem', width: '15rem' }} fileHanlder={file => setState({ ...state, fullImage: file })}>Subir nueva imagen</UploadFile>}
			{state.fullImage && <Button onClick={uploadFullImage} style={{ height: '2rem', width: '15rem' }}>Subir</Button>}

			{canEdit() && <Text bold H2>Orden</Text>}
			{canEdit() && <InputField
				style={{ width: '20rem' }}
				value={company.order ?? 1}
				onChange={v => saveCompany({ ...company, order: v })}
			/>}
			<Text bold H2>Nombre de la empresa</Text>
			<InputField
				style={{ width: '20rem' }}
				value={company.name}
				onChange={v => saveCompany({ ...company, name: v })}
			/>
			<Text bold H2>Sub Título</Text>
			<InputField
				value={company.subName}
				onChange={v => saveCompany({ ...company, subName: v })}
			/>
			<Text bold H2>Descripción de la empresa</Text>
			<InputField
				value={company.description}
				onChange={v => saveCompany({ ...company, description: v })}
			/>
			<Text bold H2>Email de contacto</Text>
			<InputField
				value={company.email}
				onChange={v => saveCompany({ ...company, email: v })}
			/>
			<Text bold H2>Palabras Claves</Text>
			<InputField
				value={company.tags}
				onChange={v => saveCompany({ ...company, tags: v })}
			/>
			<ToggleRow onClick={() => saveCompany({ ...company, isPoster: !company.isPoster })}>
				<Checkbox selected={company.isPoster} />
				<Text middle H2 bold>Es empresa Poster</Text>
			</ToggleRow>
			<Text bold H2>Archivo descargable</Text>
			{state.loading ? <Text bold H2>Subiendo...</Text> : <UploadFile style={{ height: '2rem', width: '15rem' }} fileHanlder={file => setState({ ...state, downloadFile: file })}>Subir nuevo archivo</UploadFile>}
			{state.downloadFile && <Button onClick={() => uploadDownloadFile()} style={{ height: '2rem', width: '15rem' }}>Subir</Button>}
			<div />

			<Text bold H2>Teléfono</Text>

			<InputField
				value={company.phone}
				onChange={v => saveCompany({ ...company, phone: v })}
			/>
			<Text bold H2>Página web</Text>
			<InputField
				value={company.web}
				onChange={v => saveCompany({ ...company, web: v })}
			/>
			<Text bold H2>Facebook</Text>
			<InputField
				value={company.facebook}
				onChange={v => saveCompany({ ...company, facebook: v })}
			/>
			<Text bold H2>Instagram</Text>
			<InputField
				value={company.instagram}
				onChange={v => saveCompany({ ...company, instagram: v })}
			/>
			<Text bold H2>Youtube</Text>
			<InputField
				value={company.youtube}
				onChange={v => saveCompany({ ...company, youtube: v })}
			/>
			<Text bold H2>Twitter</Text>
			<InputField
				value={company.twitter}
				onChange={v => saveCompany({ ...company, twitter: v })}
			/>
			<Text bold H2>LinkedIn</Text>
			<InputField
				value={company.linkedin}
				onChange={v => saveCompany({ ...company, linkedin: v })}
			/>
			{!isExpositor && <Text bold H2>Otros</Text>}
			{!isExpositor && <Button onClick={deleteCompany} style={{ height: '2rem', width: '15rem' }}>Eliminar empresa</Button>}
		</EditCompanyContainer>
	)
}

const ListContainer = styled.div`
    padding: 1rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-rows: 3rem auto;
    height: 100%;
`

const CompaniesGrid = styled.div`
    height: 100%;
`

const CompanyRow = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 15rem);
    height: 9rem;
    grid-gap: 2rem;
`

export const AutoRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 3rem;
    height: 3rem;
    width: min-content;
`

function ListCompany(props) {
	const [store, dispatch] = useStore()
	const [search, setSearch] = useState('')
	const auth = useAuth()
	const isExpositor = auth.isExpositor()

	// Create new company.
	const createCompanyHanlder = () => {
		const newCompany = {
			id: key(),
			name: 'Nueva Empresa',
		}

		dispatch({ type: 'set-company', payload: newCompany })

		props.selectCompany(newCompany)
	}

	// Set companies list, filter by user's permission and search bar.
	const list = []
	if (store.companies) {
		const companies = Object.values(store.companies).filter(c => {
			if (isExpositor) {
				if (auth?.user?.typeData?.company != c.id) return false
			}
			if (search) return c.name && c.name.toLowerCase().includes(search.toLowerCase())
			return true
		})

		for (let i = 0; i < companies.length; i += 3) {
			const children = []

			for (let x = i; x < Math.min(companies.length, i + 3); x++) children.push(<CompanyPreview
				key={x}
				company={companies[x]}
				onClick={() => props.selectCompany(companies[x])} />)

			list.push(<CompanyRow key={i}>{children}</CompanyRow>)
		}
	}

	const rowRenderer = ({ key, index, style }) => {
		return (
			<div key={key} style={style}>
				{list[index]}
			</div>
		)
	}

	return (
		<ListContainer>
			<AutoRow>
				<Text bold H1>Empresas</Text>
				{!isExpositor && <Button onClick={createCompanyHanlder}>Agregar nueva empresa</Button>}
				<InputField
					placeholder='Buscar Empresa'
					value={search}
					onChange={setSearch}
				/>
			</AutoRow>
			<CompaniesGrid>
				<AutoSizer>
					{
						({ height, width }) => (
							<List
								height={height}
								rowCount={list ? list.length : 0}
								rowHeight={200}
								rowRenderer={rowRenderer}
								width={width}
							/>
						)
					}
				</AutoSizer>
			</CompaniesGrid>
		</ListContainer>
	)
}

const CompanyPreviewContainer = styled.div`
    display: grid;
    grid-template-rows: auto 2rem;
    cursor: pointer;
    transition: ${p => p.theme.transitionDuration};
    :hover {
        transform: translateY(.3rem);
    }
`

function CompanyPreview(props) {
	return (
		<CompanyPreviewContainer onClick={props.onClick}>
			<Background medium><Image contain url={props.company.logo} /></Background>
			<Background><Text dark H2 bold center>{props.company.name}</Text></Background>
		</CompanyPreviewContainer>
	)
}